import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
	change_page,
} from '../../actions/toggleMenu';
import ButtonWithIcon from '../../inputs/DashboardButton';

class PageDashboard extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
		this.change_page = this.change_page.bind(this);
	}

	change_page(e) {
		this.props.change_page(e);
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	render() {
		const buttons = [];
		const buttons_super_admin = [];
		const super_html = [];
		const self = this;

		if (this.props.current_user.position == 'super_admin') {
			buttons_super_admin.push(<ButtonWithIcon name="reports" description={''} key={'reports'} label="Reports" icon="fas fa-analytics" onClick={this.change_page} />);
			buttons_super_admin.push(<ButtonWithIcon name="report-defaults" description={''} key="report-defaults" label="report-defaults" icon="fa fa-ballot-check" onClick={this.change_page}>Report Defaults</ButtonWithIcon>);
			buttons_super_admin.push(<ButtonWithIcon name="users" description={''} key={'users'} label="Users" icon="fa fa-users" onClick={this.change_page} />);
			buttons_super_admin.push(<ButtonWithIcon name="dealer-forms" description={''} key={'dealerforms'} label="DealerForms" icon="fab fa-wpforms" onClick={this.change_page} />);
			buttons_super_admin.push(<ButtonWithIcon name="notifications" description={''} key={'notifications'} label="Notifications" icon="fa fa-mail-bulk" onClick={this.change_page} />);
			buttons_super_admin.push(<ButtonWithIcon name="feed-center" description={''} key={'feed-center'} label="FeedCenter" icon="fa fa-rss" onClick={this.change_page} />);
			buttons_super_admin.push(<ButtonWithIcon name="addons" description={''} key="addons" label="addons" icon="fa fa-list-alt" onClick={this.change_page}>Plugins and Add Ons</ButtonWithIcon>);
			buttons_super_admin.push(<ButtonWithIcon name="tab-settings" description={''} key="tab-settings" label="tab-settings" icon="fa fa-ellipsis-h-alt" onClick={this.change_page}>Tab Settings</ButtonWithIcon>);
			buttons_super_admin.push(<ButtonWithIcon name="site-settings" description={''} key="site-settings" label="site-settings" icon="fa fa-cogs" onClick={this.change_page}>Site Settings</ButtonWithIcon>);
			buttons_super_admin.push(<ButtonWithIcon name="dealer-settings" description={''} key="dealer-settings" label="dealer-settings" icon="fa fa-cogs" onClick={this.change_page}>Dealer Settings</ButtonWithIcon>);
			buttons_super_admin.push(<ButtonWithIcon name="incoming-step-defaults" description={''} key="incoming-step-defaults" label="incoming-step-defaults" icon="fa fa-compress-arrows-alt" onClick={this.change_page}>Incoming Step Defaults</ButtonWithIcon>);
			buttons_super_admin.push(<ButtonWithIcon name="color-settings" description={''} key="color-settings" label="color-settings" icon="fa fa-cogs" onClick={this.change_page}>Color Settings</ButtonWithIcon>);
			buttons_super_admin.push(<ButtonWithIcon name="department-setup" description={''} key="department-setup" label="department-setup" icon="fa fa-tasks-alt" onClick={this.change_page}>Department Setup</ButtonWithIcon>);
			buttons_super_admin.push(<ButtonWithIcon name="edit-columns" description={''} key="edit-columns" label="edit-columns" icon="fa fa-sliders-h" onClick={this.change_page}>Dropdown Lists - Add/Remove Options</ButtonWithIcon>);
			buttons_super_admin.push(<ButtonWithIcon name="work-item-dashboard" description={''} key={'work-item-dashboard'} label="Work-Item Dashboard" icon="fas fa-list" onClick={this.change_page} />);
		} else if (this.props.current_user.position == 'admin') {
			if (self.dealerSettingValue('reports') === 'true') {
				buttons.push(<ButtonWithIcon name="reports" description={''} key={'reports'} label="Reports" icon="fas fa-analytics" onClick={this.change_page} />);
			}
			if (self.dealerSettingValue('email-reports') === 'true') {
				buttons.push(<ButtonWithIcon name="email-reports" description={''} key="email-reports" label="Email Reports" icon="fa fa-envelope" onClick={this.change_page}>Email Reports</ButtonWithIcon>);
			}
			if (self.dealerSettingValue('report-defaults') === 'true') {
				buttons.push(<ButtonWithIcon name="report-defaults" description={''} key="report-defaults" label="report-defaults" icon="fa fa-ballot-check" onClick={this.change_page}>Report Defaults</ButtonWithIcon>);
			}
			if (self.dealerSettingValue('goals') === 'true') {
				buttons.push(<ButtonWithIcon name="goals" description={''} key="goals" label="goals" icon="fa fa-stopwatch" onClick={this.change_page}>Goals</ButtonWithIcon>);
			}
			if (self.dealerSettingValue('users') === 'true') {
				buttons.push(<ButtonWithIcon name="users" description={''} key={'users'} label="Users" icon="fa fa-users" onClick={this.change_page} />);
			}
			if (self.dealerSettingValue('dealer-forms') === 'true') {
				buttons.push(<ButtonWithIcon name="dealer-forms" description={''} key={'dealerforms'} label="DealerForms" icon="fab fa-wpforms" onClick={this.change_page} />);
			}
			if (self.dealerSettingValue('notifications') === 'true') {
				buttons.push(<ButtonWithIcon name="notifications" description={''} key={'notifications'} label="Notifications" icon="fa fa-mail-bulk" onClick={this.change_page} />);
			}
			if (self.dealerSettingValue('feed-center') === 'true') {
				buttons.push(<ButtonWithIcon name="feed-center" description={''} key={'feed-center'} label="FeedCenter" icon="fa fa-rss" onClick={this.change_page} />);
			}
			if (self.dealerSettingValue('tab-settings') === 'true') {
				buttons.push(<ButtonWithIcon name="tab-settings" description={''} key="tab-settings" label="tab-settings" icon="fa fa-ellipsis-h-alt" onClick={this.change_page}>Tab Settings</ButtonWithIcon>);
			}
			if (self.dealerSettingValue('site-settings') === 'true') {
				buttons.push(<ButtonWithIcon name="site-settings" description={''} key="site-settings" label="site-settings" icon="fa fa-cogs" onClick={this.change_page}>Site Settings</ButtonWithIcon>);
			}
			if (self.dealerSettingValue('dealer-settings') === 'true') {
				buttons.push(<ButtonWithIcon name="dealer-settings" description={''} key="dealer-settings" label="dealer-settings" icon="fa fa-cogs" onClick={this.change_page}>Dealer Settings</ButtonWithIcon>);
			}
			if (self.dealerSettingValue('incoming-step-defaults') === 'true') {
				buttons.push(<ButtonWithIcon name="incoming-step-defaults" description={''} key="incoming-step-defaults" label="incoming-step-defaults" icon="fa fa-compress-arrows-alt" onClick={this.change_page}>Incoming Step Defaults</ButtonWithIcon>);
			}
			if (self.dealerSettingValue('color-settings') === 'true') {
				buttons.push(<ButtonWithIcon name="color-settings" description={''} key="color-settings" label="color-settings" icon="fa fa-cogs" onClick={this.change_page}>Color Settings</ButtonWithIcon>);
			}
			if (self.dealerSettingValue('department-setup') === 'true') {
				buttons.push(<ButtonWithIcon name="department-setup" description={''} key="department-setup" label="department-setup" icon="fa fa-tasks-alt" onClick={this.change_page}>Department Setup</ButtonWithIcon>);
			}
			if (self.dealerSettingValue('edit-columns') === 'true') {
				buttons.push(<ButtonWithIcon name="edit-columns" description={''} key="edit-columns" label="edit-columns" icon="fa fa-sliders-h" onClick={this.change_page}>Dropdown Lists - Add/Remove Options</ButtonWithIcon>);
			}
			if (self.dealerSettingValue('working-hours') === 'true') {
				buttons.push(<ButtonWithIcon name="working-hours" description={''} key="working-hours" label="working-hours" icon="fa fa-history" onClick={this.change_page}>Working Hours</ButtonWithIcon>);
			}
			buttons.push(<ButtonWithIcon name="work-item-dashboard" description={''} key={'work-item-dashboard'} label="Work-Item Dashboard" icon="fas fa-list" onClick={this.change_page}>Work Item Dashboard</ButtonWithIcon>);
		} else {
			if (this.props.lookupPermission('page_reports')) {
				buttons.push(<ButtonWithIcon name="reports" description={''} key={'reports'} label="Reports" icon="fas fa-analytics" onClick={this.change_page} />);
			}
			if (this.props.lookupPermission('page_users')) {
				buttons.push(<ButtonWithIcon name="users" description={''} key={'users'} label="Users" icon="fa fa-users" onClick={this.change_page} />);
			}
			if (this.props.lookupPermission('page_notifications')) {
				buttons.push(<ButtonWithIcon name="profile" description={''} key={'profile'} label="Notifications" icon="fa fa-user" onClick={this.change_page} />);
			}
		}

		buttons.push(<ButtonWithIcon name="vehicles" description={''} key={'vehicles'} label="Vehicles" icon="far fa-car" onClick={this.change_page} />);

		return (
			<div className="col-md-12 bg-light">

				<div className="col-md-12 space-top-0">
					<div className="mx-auto w-75 p-3">
						<div className="row">
							{buttons}
							{super_html}
							{buttons_super_admin}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	change_page,
};

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps, mappedActions)(PageDashboard);
