import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import TextFieldGroup from '../../inputs/TextFieldGroupNoLabel';
import TextFieldGroupWithButton from '../../inputs/TextFieldGroupWithButton';
import FlashMessageList from '../../inputs/FlashMessageList';
import Api from '../../api/reconApi.js';
import {
	addVehicle, updateVehicleDataRaw,
} from '../../actions/AllUpdates';
import {
	findVehicleByStock, findVehicleByVin, findVehicleById, vsearch, lookupDepartmentNameById,
} from '../../actions/lookups';
import {
	readableStamp,
} from '../../utils/dateFunctions';
import {
	addFlashMessage,
	deleteFlashMessages,
} from '../../actions/toggleMenu';
import {
	setFoundVehicles,
} from '../../actions/apiSetInfo';

class AddVehicle extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false, isLoading: '', input_name: '', name: '', errors: { addVehicleError: false }, stock: '', vin: '', year: '', make: '', model: '', color: '', miles: '', department_id: '', vinstatus: '', stocktype: 'used', trim: '', subdomain: this.props.current_dealership, dealership: this.props.current_dealership,
		};
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onVinChange = this.onVinChange.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.onStockTypeChangeSelect = this.onStockTypeChangeSelect.bind(this);
		this.addVehicle = this.addVehicle.bind(this);
		this.onVinDecode = this.onVinDecode.bind(this);
		this.unDeleteVehicle = this.unDeleteVehicle.bind(this);
		this.viewVehicle = this.viewVehicle.bind(this);
	}

	unDeleteVehicle(vehicle_id, vehicle_stock) {
		const self = this;
		const data = {
			id: vehicle_id,
			sold: false,
			deleted: false,
		};

		Promise.all([
			this.props.updateVehicleDataRaw(vehicle_id, data),
		]).then(() => {
			this.setState({
				stock: '',
				vin: '',
				year: '',
				make: '',
				model: '',
				miles: '',
				color: '',
				trim: '',
				department_id: '',
				stocktype: '',
				vinstatus: '',
				modalIsOpen: false,
			});
			this.props.deleteFlashMessages();
			this.props.setFoundVehicles({ vehicles: [] });
		});
	}

	openModal() {
		this.props.setFoundVehicles({ vehicles: [] });
		const dept = this.dealerSettingValue('used_car_default_department');
		this.setState({ modalIsOpen: true, department_id: parseInt(dept) });
	}

	afterOpenModal() {}

	viewVehicle(id) {
		this.props.findVehicleById(id);
		this.closeModal();
	}

	closeModal() {
		this.setState({
			stock: '',
			vin: '',
			year: '',
			make: '',
			model: '',
			miles: '',
			color: '',
			trim: '',
			department_id: '',
			stocktype: '',
			vinstatus: '',
			modalIsOpen: false,
		});
		this.props.deleteFlashMessages();
		this.props.setFoundVehicles({ vehicles: [] });
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	department(id) {
		const department = _.sortBy(_.filter(this.props.departments, o => o.id == id), 'id');
		return department[0].name || [];
	}

	addVehicle() {
		const { stock } = this.state;
		const { vin } = this.state;
		const age = Date.now() / 1000;
		const data = {
			stock: this.state.stock,
			vin: this.state.vin,
			department_id: this.state.department_id,
			subdomain: this.state.subdomain,
			dealership: this.state.dealership,
			stocktype: this.state.stocktype,
			year: this.state.year,
			make: this.state.make,
			model: this.state.model,
			miles: this.state.miles,
			color: this.state.color,
			trim: this.state.trim,
			age,
		};
		this.setState({ isLoading: true });
		const extra = {};

		this.props.addVehicle(data, extra).then((resp) => {
			if (resp == 'vehicle added') {
				this.props.addFlashMessage({
					type: 'success',
					text: 'Vehicle Has Been Added',
				});
				if (stock != '') {
					this.props.findVehicleByStock(stock.toUpperCase());
				} else if (vin != '') {
					this.props.findVehicleByVin(vin.toUpperCase());
				}
				this.setState({
					stock: '',
					vin: '',
					year: '',
					make: '',
					model: '',
					miles: '',
					color: '',
					trim: '',
					department_id: '',
					stocktype: '',
					vinstatus: '',
					modalIsOpen: true,
					isLoading: false,
				});
				setTimeout(() => {this.props.deleteFlashMessages(); }, 3000);
			} else if (resp == 'missing data') {
				this.props.addFlashMessage({
					type: 'error',
					text: 'Vehicle Is Missing Critical Data',
				});
				this.setState({ isLoading: false });
			} else if (resp.errors['addVehicle Error'] == 'stock has already been taken') {
				if (stock != '') {
					this.props.findVehicleByStock(stock.toUpperCase());
				} else if (vin != '') {
					this.props.findVehicleByVin(vin.toUpperCase());
				}

				this.props.addFlashMessage({
					type: 'error',
					text: `Vehicle Stock: ${stock} Already Exists`,
				});
				this.setState({ isLoading: false });
			} else {
				this.props.addFlashMessage({
					type: 'error',
					text: 'Add Vehicle Error',
				});
				this.setState({ isLoading: false });
			}
		});
	}

	showDepartments() {
		return Object.keys(this.props.departments).map(key => this.props.departments[key]);
	}

	departmentOptions() {
		const departments = this.showDepartments().map((department) => {
			if (department.department_type !== 'data step') {
				const obj = { value: department.id, label: department.name };
				return obj;
			}
		});
		return departments.filter(e => e);
	}

	stocktypeOptions() {
		const comparisonArray = [{ name: 'U', label: 'Used' },
			{ name: 'N', label: 'New' }, { name: 'W', label: 'Wholesale' },
			{ name: 'I', label: 'In Transit' }, { name: 'P', label: 'Prep' },
		];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	onVinDecode() {
		this.setState({
			vinstatus: `Checking Vin ${this.state.vin}`,
		});

		fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${this.state.vin}?format=json`)
			.then(res => res.json())
			.then(
				(result) => {
					const newObj = Object.keys(result.Results).map(key => result.Results[key]);

					const make = newObj.filter(item => item.Variable === 'Make');

					const year = newObj.filter(item => item.Variable === 'Model Year');

					const model = newObj.filter(item => item.Variable === 'Model');

					const trim = newObj.filter(item => item.Variable === 'Trim');

					if (make[0].Value && model[0].Value && year[0].Value) {
						this.setState({
							year: year[0].Value, make: make[0].Value, model: model[0].Value, trim: trim[0].Value, vinstatus: 'Decoding Success - Vin is Valid',
						});
					} else {
						this.setState({ vinstatus: 'Vin did not pass validation' });
					}
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					this.setState({
						vinstatus: 'Vin did not pass validation',
					});
				},
			);
	}

	onChange(event) {
		const { name } = event.target;

		this.setState({ [name]: event.target.value });
	}

	onVinChange(event) {
		const { name } = event.target;

		this.setState({ [name]: event.target.value });
		if (event.target.value.length == 17) {
			this.props.findVehicleByVin(event.target.value);
		}
	}

	onChangeSelect(e) {
		this.setState({ department_id: e.value });
	}

	onStockTypeChangeSelect(e) {
		this.setState({ stocktype: e.value });
	}

	render() {
		const { errors } = this.state;

		const defaultStyle = {
			background: this.props.bg_color,
			backgroundColor: this.props.bg_color,
			color: this.props.font_color,
		};

		return (
			<div className="input-group-append btn-dark">
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					contentLabel="PickColor"
					className="d-flex justify-content-center pad-bottom-2 space-bottom-2"
					contentLabel="Add Vehicle"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="bg-light col-md-8 md-auto space-top-2 pad-top-1 pad-bottom-1 pad-left-2 pad-right-2">
						<div className="modal-content">

							<div className="container">
								<h4 className="modal-title d-inline">Add Vehicle</h4>
								<button className="btn btn-sm btn-danger float-end" onClick={this.closeModal}>Close</button>
							</div>

							<div className="modal-body add-vehicle">
								<div className="card-block bg-white">
								<div className="row text-center">
									<br />
									{/* Display VIN status button if vinstatus is set */}
									{this.state.vinstatus ? <button className="btn btn-success">{this.state.vinstatus}</button> : ''}
									{this.state.isLoading ? <button className="btn btn-success">Vehicle is being added and the department counts updated..</button> : ''}
									{/* Display FlashMessageList component */}
									<FlashMessageList {...this.props} />
									{/* Map through found_vehicles and display vehicle details */}
									{this.props.found_vehicles.length > 0 && (
										<table className="table table-striped">
											<thead>
												<tr>
													<th>Stock, VIN</th>
													<th>Year, Make, Model</th>
													<th>Date Created</th>
													<th>Current Department</th>
													<th>Status</th>
													<th>Active</th>
													<th>View</th>
												</tr>
											</thead>
											<tbody>
												{this.props.found_vehicles.map((vehicle, index) => (
													<tr key={index}>
														<td>
															<p><strong>Stock:</strong> {vehicle.stock}</p>
															<p><strong>VIN:</strong> {vehicle.vin}</p>
														</td>
														<td>
															<p><strong>{vehicle.year} {vehicle.make} {vehicle.model} </strong></p>
														</td>
														<td>{readableStamp(vehicle.created_at)}</td>
														<td>{this.props.lookupDepartmentNameById(vehicle.department_id)}</td>
														<td>
															<p><strong>Sold Status:</strong> {vehicle.sold ? 'Sold' : 'Not Sold'}</p>
															<p><strong>Deleted:</strong> {vehicle.deleted ? 'Deleted' : 'Not Deleted'}</p>
														</td>
														<td>
															{(vehicle.deleted || vehicle.sold) ? <button onClick={() => this.unDeleteVehicle(vehicle.id, vehicle.stock)} className="btn btn-success">Restore Vehicle</button> : 'Yes'}
														</td>
														<td>
															{vehicle.id && (
																<button className="btn btn-success" onClick={() => this.viewVehicle(vehicle.id)}>View Vehicle</button>
															)}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									)}
								</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Vin Number</label>
												<TextFieldGroupWithButton
													field={'vin'}
													value={this.state.vin}
													label={'Vin Number'}
													error={''}
													type={'text'}
													placeholder={'Vin'}
													onChange={this.onVinChange}
													onClick={this.onVinDecode}
												/>

											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Stock Number</label>
												<TextFieldGroup
													field={'stock'}
													value={this.state.stock}
													label={'Stock Number'}
													error={''}
													type={'text'}
													placeholder={'Stock Number'}
													onChange={this.onChange}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Stock Type</label>
												<Select
													onChange={this.onStockTypeChangeSelect}
													value={this.stocktypeOptions().filter(({ value }) => value === this.state.stocktype)}
													name={'stocktype'}
													options={this.stocktypeOptions()}
													className="text-capitalize"
													clearable={false}
													searchable={false}
													menuContainerStyle={{ zIndex: 5 }}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Year</label>
												<TextFieldGroup
													field={'year'}
													value={this.state.year}
													label={'Year'}
													error={''}
													type={'text'}
													placeholder={'Year'}
													onChange={this.onChange}
												/>
											</div>

										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Make</label>
												<TextFieldGroup
													field={'make'}
													value={this.state.make}
													label={'make'}
													error={''}
													type={'text'}
													placeholder={'Make'}
													onChange={this.onChange}
												/>
											</div>

										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Model</label>
												<TextFieldGroup
													field={'model'}
													value={this.state.model}
													label={'Model'}
													error={''}
													type={'text'}
													placeholder={'Model'}
													onChange={this.onChange}
												/>
											</div>

										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Trim</label>
												<TextFieldGroup
													field={'trim'}
													value={this.state.trim}
													label={'Trim'}
													error={''}
													type={'text'}
													placeholder={'Trim'}
													onChange={this.onChange}
												/>
											</div>

										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Miles</label>
												<TextFieldGroup
													field={'miles'}
													value={this.state.miles}
													label={'miles'}
													error={''}
													type={'text'}
													placeholder={'Miles'}
													onChange={this.onChange}
												/>
											</div>

										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Color</label>
												<TextFieldGroup
													field={'color'}
													value={this.state.color}
													label={'Color'}
													error={''}
													type={'text'}
													placeholder={'Color'}
													onChange={this.onChange}
												/>
											</div>

										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Add to Step</label>
												<Select
													onChange={this.onChangeSelect}
													value={this.departmentOptions().filter(({ value }) => value === this.state.department_id)}
													name={'department_id'}
													options={this.departmentOptions()}
													className="text-capitalize"
													clearable={false}
													searchable={false}
													menuContainerStyle={{ zIndex: 5 }}
												/>

											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
							{!this.state.isLoading && (
								<button type="button" className="btn btn-success" onClick={this.addVehicle}>Create Vehicle</button>
							)}
							</div>

						</div>
					</div>

				</Modal>
				<span className="input-group-text btn btn-sm btn-primary input-sm" onClick={this.openModal}>
					<i className="fal fa-plus" />
					{' '}
Add Vehicle
				</span>
			</div>
		);
	}
}

const mappedActions = {
	addVehicle,
	addFlashMessage,
	deleteFlashMessages,
	findVehicleByStock,
	findVehicleByVin,
	findVehicleById,
	updateVehicleDataRaw,
	vsearch,
	lookupDepartmentNameById,
	setFoundVehicles,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.departments,
		current_dealership: state.settings_dealerships.current_dealership,
		found_vehicles: state.settings_refresh.found_vehicles,
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps, mappedActions)(AddVehicle);
