import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';

class AutomaticMoves extends Component {
	constructor(props) {
		super(props);

		this.state = { current_default_profile: this.props.current_default_profile, errors: { addDepartmentPropertyError: false }, option_name: '' };
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="card space-top-0">

					<h3 className="text-center pad-top-1">Automatic Moves</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text">
							<EditDealershipSettings {...this.props} type={'select-departments'} name="timedstart1_car_default_department" label="Set Timed Move Out Of:" value={this.dealerSettingValue('timedstart1_car_default_department')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="timedinto1_car_default_department" label="Move Into" value={this.dealerSettingValue('timedinto1_car_default_department')} />
							<EditDealershipSettings {...this.props} type={'text'} name="timedstart1_days" label="After # of Days" value={this.dealerSettingValue('timedstart1_days')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="timedstart2_car_default_department" label="Set Timed Move Out Of:" value={this.dealerSettingValue('timedstart2_car_default_department')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="timedinto2_car_default_department" label="Move Into" value={this.dealerSettingValue('timedinto2_car_default_department')} />
							<EditDealershipSettings {...this.props} type={'text'} name="timedstart2_days" label="After # of Days" value={this.dealerSettingValue('timedstart2_days')} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps)(AutomaticMoves);
