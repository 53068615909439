import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';
import EditDealershipSettingsWithDealership from '../../inputs/EditDealershipSettingsWithDealership';
import EditInstallation from '../../inputs/EditInstallation';
import DropBoxDealer from '../../inputs/FileUploadButton/DropBoxDealer';

class SiteSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value1: this.props.override_value_1, current_default_profile: this.props.current_default_profile, errors: { addDepartmentPropertyError: false }, option_name: '',
		};
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	dealerSettingValueDealership(name) {
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}
	

	render() {
		const { installation } = this.props;
		const vehicle_tab_override = this.dealerSettingValue('vehicle_tab_override') || 'false';
		const timedashboard_1 = this.dealerSettingValue('timedashboard_1') || 'false';
		const timedashboard_2 = this.dealerSettingValue('timedashboard_2') || 'false';
		const timedashboard_3 = this.dealerSettingValue('timedashboard_3') || 'false';
		const timedashboard_4 = this.dealerSettingValue('timedashboard_4') || 'false';
		const timedashboard_5 = this.dealerSettingValue('timedashboard_5') || 'false';
		const timedashboard_6 = this.dealerSettingValue('timedashboard_6') || 'false';
		const timedashboard_7 = this.dealerSettingValue('timedashboard_7') || 'false';
		const timedashboard_8 = this.dealerSettingValue('timedashboard_8') || 'false';
		const timedashboard_9 = this.dealerSettingValue('timedashboard_9') || 'false';
		const timedashboard_10 = this.dealerSettingValue('timedashboard_10') || 'false';

		const timedashboard_1_time = this.dealerSettingValue('timedashboard_1_time') || 'false';
		const timedashboard_2_time = this.dealerSettingValue('timedashboard_2_time') || 'false';
		const timedashboard_3_time = this.dealerSettingValue('timedashboard_3_time') || 'false';
		const timedashboard_4_time = this.dealerSettingValue('timedashboard_4_time') || 'false';
		const timedashboard_5_time = this.dealerSettingValue('timedashboard_5_time') || 'false';
		const timedashboard_6_time = this.dealerSettingValue('timedashboard_6_time') || 'false';
		const timedashboard_7_time = this.dealerSettingValue('timedashboard_7_time') || 'false';
		const timedashboard_8_time = this.dealerSettingValue('timedashboard_8_time') || 'false';
		const timedashboard_9_time = this.dealerSettingValue('timedashboard_9_time') || 'false';
		const timedashboard_10_time = this.dealerSettingValue('timedashboard_10_time') || 'false';

		const html = [];
		if (vehicle_tab_override === 'true') {
			let i = 1;
			while (i < 16) {
				const override_value = this.dealerSettingValue(`override_value_${i}`) || '';
				const background_color_override = this.dealerSettingValue(`background_color_override_${override_value}`);
				const text_color_override = this.dealerSettingValue(`text_color_override_${override_value}`);

				html.push(<div className="row">
					<div className="col-md-4">
						<EditDealershipSettings {...this.props} type={'text'} name={`override_value_${i}`} label={`override_value_${i}`} value={override_value} />
					</div>
					<div className="col-md-4">
						<EditDealershipSettings {...this.props} type={'colorpicker'} name={`background_color_override_${override_value}`} label={`background_color_override_${override_value}`} value={background_color_override} />
					</div>
					<div className="col-md-4">
						<EditDealershipSettings {...this.props} type={'colorpicker'} name={`text_color_override_${override_value}`} label={`text_color_override_${override_value}`} value={text_color_override} />
					</div>
				</div>);

				i += 1;
			}
		}

		return (
			<div className="col-md-12">

				<div className="card space-top-0 v80">

					<h3 className="text-center pad-top-1">Dealer Logo</h3>

					<div className="card bg-light space-top-1 mx-auto w-75 p-3">

						<div className="row">
							<div className="col-md-3 text-center align-middle pad-top-2">
								<img src={installation.logo} className={'img-thumbnail'} />
							</div>
							<div className="col-md-9">
								<div className="row">
									<EditInstallation {...this.props} type={'text-logo'} name="logo" label="Dealer Logo URL" value={installation.logo} parentid={installation.id} />
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="card card-default">
											<div className="card-heading" />
											<div className="card-body drop-container">

												<DropBoxDealer {...this.props} installation={installation.id} siteid={`${installation.id}_logo`} />
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_1" label="Dashboard Report Spot 1" value={this.dealerSettingValue('timedashboard_1')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_1_time" label="Dashboard Report Time Type spot 1" value={this.dealerSettingValue('timedashboard_1_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_2" label="Dashboard Report Spot 2" value={this.dealerSettingValue('timedashboard_2')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_2_time" label="Dashboard Report Time Type spot 2" value={this.dealerSettingValue('timedashboard_2_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_3" label="Dashboard Report Spot 3" value={this.dealerSettingValue('timedashboard_3')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_3_time" label="Dashboard Report Time Type spot 3" value={this.dealerSettingValue('timedashboard_3_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_4" label="Dashboard Report Spot 4" value={this.dealerSettingValue('timedashboard_4')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_4_time" label="Dashboard Report Time Type spot 4" value={this.dealerSettingValue('timedashboard_4_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_5" label="Dashboard Report Spot 5" value={this.dealerSettingValue('timedashboard_5')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_5_time" label="Dashboard Report Time Type spot 5" value={this.dealerSettingValue('timedashboard_5_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_6" label="Dashboard Report Spot 6" value={this.dealerSettingValue('timedashboard_6')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_6_time" label="Dashboard Report Time Type spot 6" value={this.dealerSettingValue('timedashboard_6_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_7" label="Dashboard Report Spot 7" value={this.dealerSettingValue('timedashboard_7')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_7_time" label="Dashboard Report Time Type spot 7" value={this.dealerSettingValue('timedashboard_7_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_8" label="Dashboard Report Spot 8" value={this.dealerSettingValue('timedashboard_8')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_8_time" label="Dashboard Report Time Type spot 8" value={this.dealerSettingValue('timedashboard_8_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_9" label="Dashboard Report Spot 9" value={this.dealerSettingValue('timedashboard_9')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_9_time" label="Dashboard Report Time Type spot 9" value={this.dealerSettingValue('timedashboard_9_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_10" label="Dashboard Report Spot 10" value={this.dealerSettingValue('timedashboard_10')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_10_time" label="Dashboard Report Time Type spot 10" value={this.dealerSettingValue('timedashboard_10_time')} />
						</div>
					</div>
					<h3 className="text-center pad-top-1">Dealer INFO</h3>

					<div className="card bg-light space-top-1 mx-auto w-75 p-3">
						<div className="row">
							<p>Show Combined Inventory from MultiStore Group</p>
							<EditDealershipSettings {...this.props} type={'switch'} name="combined_inventory" label="Combined Inventory" value={this.dealerSettingValue('combined_inventory')} />
						</div>
						<div className="row">
							<div className="col-md-12">
								<EditInstallation {...this.props} type={'text'} name="default_time_range" label="default_time_range" value={installation.default_time_range} parentid={installation.id} />
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<EditInstallation {...this.props} type={'text'} name="recon_process" label="recon_process" value={installation.recon_process || ''} parentid={installation.id} />
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<EditInstallation {...this.props} type={'text'} name="softname" label="Dealership Name" value={installation.softname} parentid={installation.id} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<EditInstallation {...this.props} type={'text'} name="street1" label="Street" value={installation.street1} parentid={installation.id} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-6">
								<EditInstallation {...this.props} type={'text'} name="city" label="City" value={installation.city} parentid={installation.id} />
							</div>
							<div className="col-md-3">
								<EditInstallation {...this.props} type={'text'} name="state" label="State" value={installation.state} parentid={installation.id} />
							</div>
							<div className="col-md-3">
								<EditInstallation {...this.props} type={'text'} name="zip" label="Zip" value={installation.zip} parentid={installation.id} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<EditDealershipSettingsWithDealership {...this.props} type={'number'} name="default_labor_rate" label="Default Labor Rate" value={this.dealerSettingValueDealership('default_labor_rate')} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<EditDealershipSettings {...this.props} type={'select-timezone'} name="dealership_time_zone" label="Dealership Time Zone" value={this.dealerSettingValue('dealership_time_zone')} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<EditInstallation {...this.props} type={'text'} name="phone" label="Phone" value={installation.phone} parentid={installation.id} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<EditInstallation {...this.props} type={'text'} name="website" label="Website" value={installation.website} parentid={installation.id} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<EditInstallation {...this.props} type={'text'} name="billing_contact" label="Billing Contact" value={installation.billing_contact} parentid={installation.id} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-12">
								<EditInstallation {...this.props} type={'text'} name="billing_email" label="Billing Email" value={installation.billing_email} parentid={installation.id} />
							</div>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps)(SiteSettings);
