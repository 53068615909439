import _ from 'lodash';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from './Dropdown';
import SelectMultiple from './DropdownMultiple';
import {
	lookupRecipients,
	lookupDepartmentByName,
	vsearch,
	activeVehicle,
	lookupVehicleColumnById,
} from '../actions/lookups';
import {
	getDayMonthYearFromDate,
	userStamp,
	getDateTime,
} from '../utils/dateFunctions';
import {
	addVehicleProperty,
	updateVehicle,
	updateVehicleData,
	addVehiclePropertyRaw,
	updateVehicleDepartment,
} from '../actions/AllUpdates';
import {
	updatePartOrder,
	addPartOrder,
	addPartOrderRaw,
} from '../actions/apiPartOrders';
import {
	processDepartments,
	refreshDepartment,
	getCurrentDepartment,
} from '../actions/apiGetInfo';
import {
	setVehicleTab,
} from '../actions/apiSetInfo';
import {
	updateWorkItem,
} from '../actions/apiWorkItems';
import {
	updateEstimate,
	sendEstimate,
	addEstimate,
	addEstimateRaw,
	sendEstimateRaw,
} from '../actions/apiEstimates';
import {
	addVehicleNote,
	addVehicleNoteRaw,
} from '../actions/apiVehicleNotes';

import {
	addMehanicalNotification,
} from '../actions/apiNotifications';

class AllInputs extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { value: this.props.value || '', newurl: '' };
		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeChecked = this.onChangeChecked.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.onChangeCheckedCompleted = this.onChangeCheckedCompleted.bind(this);
		this.onChangeCheckedNeeded = this.onChangeCheckedNeeded.bind(this);
		this.filterUsers = this.filterUsers.bind(this);
		this.selectPayType = this.selectPayType.bind(this);
		this.notification_setting = this.notification_setting.bind(this);
		this.vehicleProperty = this.vehicleProperty.bind(this);
		this.selectWorkItem = this.selectWorkItem.bind(this);
	}

	onChange(e) {
		this.setState({ value: e.target.value });
	}

	vehicleProperty(name) {
		const value = _.filter(this.props.vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	notification_setting(department_id) {
		if (this.props.notification_settings.length > 0) {
			const notifications = _.filter(this.props.notification_settings, { department_id, value: true, note_type: 'vehicle_entry' });

			return notifications || [];
		}
		return [];
	}

	filterUsers(recipients) {
		const selected_recipients = recipients.split(',') || [];
		const users = this.props.lookupRecipients();
		const options = users.map((option) => {
			let selected = false;
			const dataid = 0;
			if (selected_recipients.includes(option.id.toString())) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.user_name, estimate_id: this.props.estimate_id, selected,
			};
			return obj;
		});
		return options || [];
	}

	selectOptions(raw_options, value) {
		const select_options = raw_options || [];
		const options = select_options.map((option) => {
			let selected = false;
			const dataid = 0;
			if (value.toLowerCase() === option.name.toLowerCase()) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, selected,
			};
			return obj;
		});
		return options || [];
	}

	selectPayType() {
		const select_options = [{ name: 'Internal Pay', id: 1 }, { name: 'Customer Pay', id: 2 }, { name: 'Warranty Pay', id: 3 }];
		const options = select_options.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.props.value === option.name) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.name, selected, estimate_id: this.props.estimate_id,
			};
			return obj;
		});
		return options || [];
	}

	selectWorkItem() {
		const select_options = [{ name: 'Internal Pay', id: 1 }, { name: 'Customer Pay', id: 2 }, { name: 'Warranty Pay', id: 3 }];
		const options = select_options.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.props.value === option.name) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.name, selected, estimate_id: this.props.estimate_id,
			};
			return obj;
		});
		return options || [];
	}

	onBlur(e) {
		if (e.target.value !== this.props.value) {
			const errorType = 'updateVehicleError';
			const vehicle_id = this.props.parentid;
			var vehicle = this.props.vehicle;
			const { rowid } = this.props;
			const key = this.props.name;
			const { value } = e.target;
			const uid = this.props.current_user.user;
			const user_name = this.props.current_user.user_name || this.props.current_user.email;

			if (this.props.updatetype == 'vehicleproperty') {
				const data = {
					vehicle_id,
					key,
					value,
					uid,
					user_name,
				};

				Promise.all([
					this.props.addVehicleProperty(data, errorType),
				]).then(() => {
					const { vehicle_filter, department } = this.props;

					if (vehicle_filter.type === 'searched') {
						this.props.vsearch(vehicle_filter.value);
					} else {
						this.props.getCurrentDepartment(parseInt(department));
					}
				});
			}
			if (this.props.updatetype == 'vehicle') {
				this.props.updateVehicle(vehicle_id, key, value);
			}

			if (this.props.updatetype == 'part_order') {
				let newvalue = value;
				if (key === 'Date Ordered' || key === 'Date Expected') {
					newvalue = getDayMonthYearFromDate(value);
				}
				var id = this.props.part_order_id;
				var data = {
					id,
					[key.replace(/ /g, '_').toLowerCase()]: newvalue,
					vehicle_id,
				};
				this.props.updatePartOrder(id, data, errorType);
			}

			if (this.props.updatetype == 'estimate') {
				var id = this.props.estimate_id;
				let newvalue = value;

				if (key === 'Created' || key === 'Closed') {
					newvalue = getDayMonthYearFromDate(value);
				}

				var data = {
					id,
					[key]: newvalue,
					vehicle_id,
				};

				this.props.updateEstimate(id, data, errorType);
			}
		}
	}

	onChangeChecked(e) {
		this.setState({ value: e.target.checked ? 'true' : '' });
		var vehicle_id = parseInt(this.props.parentid);
		var vehicle = this.props.vehicle;
		var vehicle_column = this.props.lookupVehicleColumnById(this.props.vehicle_column_id);

		if (this.props.updatetype === 'work_item') {
			const errorType = 'updateWorkItemError';
			const id = this.props.dataid;

			const value = e.target.checked ? 'true' : '';
			this.setState({ [e.target.name]: value });

			const user_name = this.props.current_user.user_name || this.props.current_user.email;

			if (e.target.name === 'recommended') {
				var key1 = 'recommended_by';
				var key2 = 'recommended_datetime';
			} else if (e.target.name === 'required') {
				var key1 = 'required_by';
				var key2 = 'required_datetime';
			} else if (e.target.name === 'work_approved') {
				var key1 = 'work_approved_by';
				var key2 = 'work_approved_datetime';
			} else if (e.target.name === 'work_declined') {
				var key1 = 'work_declined_by';
				var key2 = 'work_declined_datetime';
			} else if (e.target.name === 'item_completed') {
				var key1 = 'item_completed_by';
				var key2 = 'item_completed_datetime';
			}

			const data = {
				id,
				vehicle_id: vehicle.id,
				[e.target.name]: value,
				[key1]: user_name,
				[key2]: getDateTime(),
			};

			this.props.updateWorkItem(id, data, errorType);
		} else if (this.props.updatetype == 'recall_completed') {
			var errorType = 'updateVehicleError';
			var vehicle_id = parseInt(this.props.parentid);
			var { rowid } = this.props;
			var key = this.props.name;
			var value = e.target.checked ? 'true' : '';
			var uid = this.props.current_user.user;
			var user_name = this.props.current_user.user_name || this.props.current_user.email;

			var data = {
				vehicle_id,
				key,
				value,
				uid,
				user_name,
			};
			this.props.addVehicleProperty(data, errorType);
		} else if (this.props.updatetype == 'part_order') {
			this.setState({ value: e.target.checked ? true : '' });
			var vehicle_id = parseInt(this.props.parentid);
			var id = this.props.part_order_id;
			var value = e.target.checked ? 'true' : '';
			var key = this.props.name.replace(/ /g, '_').toLowerCase();
			var data = {
				id,
				[key]: value,
				vehicle_id,
			};

			this.props.updatePartOrder(id, data, errorType);
		} else if (this.props.updatetype == 'vehicleproperty') {
			var errorType = 'updateVehicleError';
			var { rowid } = this.props;
			var key = this.props.name;
			var keylowercase = this.props.name.toLowerCase();
			var value = e.target.checked ? 'true' : '';
			var uid = this.props.current_user.user;
			var user_name = this.props.current_user.user_name || this.props.current_user.email;

			var data = {
				vehicle_id,
				key,
				value,
				uid,
				user_name,
			};

			let department = [];

			if (keylowercase === 'pictures taken') {
				var data2 = {
					vehicle_id,
					value: 'Pictures Taken',
					uid: this.props.current_user.user,
				};
				var pictures = this.props.lookupDepartmentByName('pictures');
				var notifications = this.notification_setting(pictures.id);
				notifications.map((note) => {
					const data3 = {
						note_type: 'vehicle_entry',
						message: 'Pictures Taken',
						user_id: note.user_id,
					};

					this.props.addMehanicalNotification(data3, 'addMehanicalNotification');
				});
			} else if (key == 'at auction' && value == 'true') {
				if (this.props.installation.database === 'marhofer') {
					department = this.props.lookupDepartmentByName('update automall to auction');
				}
			} else if (key == 'to automall' && value == 'true') {
				if (this.props.installation.database === 'marhofer') {
					department = this.props.lookupDepartmentByName('wholesale - at automall');
				}
			}

			if (this.props.installation.database === 'walser') {
				if (key == 'work complete' && value == 'true') {
					if (this.props.vehicle.subdomain !== this.props.vehicle.dealership) {
						var errorType = 'Change Subdomain';
						var data2 = {
							id: vehicle_id,
							subdomain: this.props.vehicle.dealership,
						};

						this.props.updateVehicleData(vehicle_id, data2, errorType);
					}

					if ((this.vehicleProperty('certified') === 'true') || (this.vehicleProperty('precision used') === 'true')) {
						department = this.props.lookupDepartmentByName('price vehicle');
					} else if (this.vehicleProperty('not certified') === 'true') {
						department = this.props.lookupDepartmentByName('price vehicle');
					} else if (this.vehicleProperty('wholesale') === 'true') {
						department = this.props.lookupDepartmentByName('wholesale detail needed');
					}
				} else if (key == 'vehicle priced' && value === 'true') {
					const dept = this.props.lookupDepartmentByName('price vehicle');
					const touch_up_only = this.vehicleProperty('touch up only');
					const status_estimates = this.vehicleProperty('status_estimates');

					if (parseInt(dept.id) === parseInt(vehicle.department_id)) {
						if ((this.vehicleProperty('certified') === 'true') || (this.vehicleProperty('precision used') === 'true')) {
							department = this.props.lookupDepartmentByName('certification data entry');
						} else if ((this.props.vehicle.make.toLowerCase() === 'bmw') || (this.props.vehicle.make.toLowerCase() === 'mini') || (this.props.vehicle.make.toLowerCase() === 'mercedes-benz')) {
							department = this.props.lookupDepartmentByName('certification data entry');
						} else if (touch_up_only == 'true') {
							department = this.props.lookupDepartmentByName('stage four - detail assignment');
						} else if (status_estimates == 'success') {
							department = this.props.lookupDepartmentByName('stage four - detail assignment');
						} else {
							department = this.props.lookupDepartmentByName('stage three - cosmetic work items - ready');
						}
					}
				} else if (key == 'arrived and updated in tekion' && value === 'true') {
					if (this.props.installation.dealership === 'jaguarlandroverwichita') {
						department = this.props.lookupDepartmentByName('pdi');
					} else if (this.vehicleProperty('arrived from dealer trade') === 'true') {
						department = this.props.lookupDepartmentByName('prep for lot (at store)');
					} else if (this.vehicleProperty('arrived from manufacturer') === 'true') {
						department = this.props.lookupDepartmentByName('pdi');
					}
				} else if (key == 'change status tekion' && value === 'true') {
					const dept_five = this.props.lookupDepartmentByName('certification data entry');
					if (parseInt(dept_five.id) === parseInt(vehicle.department_id)) {
						if ((this.vehicleProperty('change status and description vauto') === 'true') && (this.vehicleProperty('update oem system') === 'true')) {
							const touch_up_only = this.vehicleProperty('touch up only');
							if (touch_up_only == 'true') {
								department = this.props.lookupDepartmentByName('stage four - detail assignment');
							} else {
								department = this.props.lookupDepartmentByName('stage three - cosmetic work items - ready');
							}
						}
					}
				} else if (key == 'change status and description vauto' && value === 'true') {
					const dept_five = this.props.lookupDepartmentByName('certification data entry');
					if (parseInt(dept_five.id) === parseInt(vehicle.department_id)) {
						if ((this.vehicleProperty('change status tekion') === 'true') && (this.vehicleProperty('update oem system') === 'true')) {
							const touch_up_only = this.vehicleProperty('touch up only');
							if (touch_up_only == 'true') {
								department = this.props.lookupDepartmentByName('stage four - detail assignment');
							} else {
								department = this.props.lookupDepartmentByName('stage three - cosmetic work items - ready');
							}
						}
					}
				} else if (key == 'update oem system' && value === 'true') {
					const dept_five = this.props.lookupDepartmentByName('certification data entry');
					if (parseInt(dept_five.id) === parseInt(vehicle.department_id)) {
						if ((this.vehicleProperty('change status tekion') === 'true') && (this.vehicleProperty('change status and description vauto') === 'true')) {
							const touch_up_only = this.vehicleProperty('touch up only');
							if (touch_up_only == 'true') {
								department = this.props.lookupDepartmentByName('stage four - detail assignment');
							} else {
								department = this.props.lookupDepartmentByName('stage three - cosmetic work items - ready');
							}
						}
					}
				} else if (key == 'descriptions' && value === 'true') {
					const dept_five = this.props.lookupDepartmentByName('stage one - initial');
					if (parseInt(dept_five.id) === parseInt(vehicle.department_id)) {
						if ((this.vehicleProperty('original msrp') === 'true')) {
							department = this.props.lookupDepartmentByName('awaiting assignment');
						}
					}
				} else if (key == 'original msrp' && value === 'true') {
					const dept_five = this.props.lookupDepartmentByName('stage one - initial');
					if (parseInt(dept_five.id) === parseInt(vehicle.department_id)) {
						if ((this.vehicleProperty('descriptions') === 'true')) {
							department = this.props.lookupDepartmentByName('awaiting assignment');
						}
					}
				} else if (key == 'striping completed' && value === 'true') {
					const dept_five = this.props.lookupDepartmentByName('striping');
					if (parseInt(dept_five.id) === parseInt(vehicle.department_id)) {
						if ((this.vehicleProperty('striping verified') === 'true')) {
							if ((this.vehicleProperty('dealer work needed') === 'needed')) {
								department = this.props.lookupDepartmentByName('dealer work needed');
							} else {
								department = this.props.lookupDepartmentByName('completed retail used');
							}
						}
					}
				} else if (key == 'striping verified' && value === 'true') {
					const dept_five = this.props.lookupDepartmentByName('striping');
					if (parseInt(dept_five.id) === parseInt(vehicle.department_id)) {
						if ((this.vehicleProperty('striping completed') === 'true')) {
							if ((this.vehicleProperty('dealer work needed') === 'needed')) {
								department = this.props.lookupDepartmentByName('dealer work needed');
							} else {
								department = this.props.lookupDepartmentByName('completed retail used');
							}
						}
					}
				} else if ((key == 'on site - interior' || key == 'sublet detail') && (value === 'completed')) {
					const touch_up_complete_needed = this.vehicleProperty('on site - exterior and touch up');
					const anderson_auto_interior_needed = this.vehicleProperty('anderson auto interior - needed');
					const anderson_auto_interior = this.vehicleProperty('anderson auto interior');

					if (anderson_auto_interior_needed === 'true') {
						const data_interior = {
							vehicle_id,
							value: 'needed',
							key: 'anderson auto interior',
						};

						this.props.addVehicleProperty(data_interior, 'anderson auto interior');
					} else if (touch_up_complete_needed !== 'completed') {
							var data_est = {
								vehicle_id,
								value: 'needed',
								key: 'on site - exterior and touch up',
							};

							this.props.addVehicleProperty(data_est, 'adding touch up');
					}
				} else if ((key == 'anderson auto interior') && (value === 'completed')) {
					const touch_up_complete_needed = this.vehicleProperty('on site - exterior and touch up');

					if (touch_up_complete_needed !== 'completed') {
						var data_est = {
							vehicle_id,
							value: 'needed',
							key: 'on site - exterior and touch up',
						};

						this.props.addVehicleProperty(data_est, 'adding touch up');
					}
				} else if (key == 'digital listings completed' && value === 'true') {
					if (this.vehicleProperty('pictures completed') == 'true' && this.vehicleProperty('description completed') == 'true' && this.vehicleProperty('msrp') == 'true' && this.vehicleProperty('pics downloaded') == 'true' && this.vehicleProperty('pics uploaded') == 'true' && this.vehicleProperty('tekion updated') == 'true') {
						department = this.props.lookupDepartmentByName('audit needed');
					}
				}
			}
			var vehicle_tab = this.props.vehicle_tab;
			if (department.id) {
				Promise.all([
					this.props.addVehicleProperty(data, errorType), this.props.updateVehicleDepartment(vehicle_id, 'department_id', parseInt(department.id), vehicle),
				]).then(() => {
					const { vehicle_filter, department } = this.props;

					if (vehicle_filter.type === 'searched') {
						this.props.vsearch(vehicle_filter.value);
						this.props.refreshDepartment(vehicle.department_id);
						this.props.refreshDepartment(department.id);
					} else {
						this.props.getCurrentDepartment(parseInt(department));
						this.props.refreshDepartment(department.id);
					}
					this.props.activeVehicle(vehicle.id);
				}).then(() => {
						setTimeout(() => {
							if (vehicle_tab == 'vehicle-body') {
								this.props.setVehicleTab('tab_history', vehicle_id);
								this.props.setVehicleTab('vehicle-body', vehicle_id);
							}
}, 3000);
					});
			} else {
				Promise.all([
					this.props.addVehicleProperty(data, errorType),
				]).then(() => {
					const { vehicle_filter, department } = this.props;

					if (vehicle_filter.type === 'searched') {
						this.props.vsearch(vehicle_filter.value);
					} else {
						this.props.getCurrentDepartment(parseInt(department));
						this.props.refreshDepartment(parseInt(vehicle_filter.value));
					}
					this.props.activeVehicle(vehicle_id);
				}).then(() => {
						setTimeout(() => {
							if (vehicle_tab == 'vehicle-body') {
								this.props.setVehicleTab('tab_history', vehicle_id);
								this.props.setVehicleTab('vehicle-body', vehicle_id);
							}
}, 3000);
					});
			}
		}
	}

	onChangeCheckedNeeded(e) {
		this.setState({ value: e.target.checked ? 'needed' : '' });
		var vehicle = this.props.vehicle;
		if (this.props.updatetype == 'vehicleproperty') {
			const errorType = 'updateVehicleError';
			const vehicle_id = this.props.parentid;
			const { rowid } = this.props;
			const key = this.props.name;
			const value = e.target.checked ? 'needed' : '';
			const uid = this.props.current_user.user;
			const user_name = this.props.current_user.user_name || this.props.current_user.email;

			const data = {
				vehicle_id,
				key,
				value,
				uid,
				user_name,
			};

			Promise.all([
				this.props.addVehicleProperty(data, errorType),
			]).then(() => {
				const { vehicle_filter, department } = this.props;
				this.props.activeVehicle(vehicle_id);

				if (vehicle_filter.type === 'searched') {
					this.props.vsearch(vehicle_filter.value);
				} else {
					this.props.getCurrentDepartment(parseInt(department));
				}
			});
		}
	}

	onChangeCheckedCompleted(e) {
		this.setState({ value: e.target.checked ? 'completed' : '' });
		const vehicle_id = this.props.parentid;
		const vehicle = this.props.vehicle;

		if (this.props.updatetype == 'vehicleproperty') {
			const errorType = 'updateVehicleError';
			const { rowid } = this.props;
			const key = this.props.name;
			const value = e.target.checked ? 'completed' : '';
			const uid = this.props.current_user.user;
			const user_name = this.props.current_user.user_name || this.props.current_user.email;

			const data = {
				vehicle_id,
				key,
				value,
				uid,
				user_name,
			};

			Promise.all([
				this.props.addVehicleProperty(data, errorType),
			]).then(() => {
				const { vehicle_filter, department } = this.props;
				this.props.activeVehicle(vehicle_id);

				if (vehicle_filter.type === 'searched') {
					this.props.vsearch(vehicle_filter.value);
				} else {
					this.props.getCurrentDepartment(parseInt(department));
				}
			});

			let department = [];
			let data_est = {};
			const touch_up_complete_needed = this.vehicleProperty('on site - exterior and touch up');

			if ((key == 'on site - exterior and touch up') && (value == 'completed')) {
				const dept_four = this.props.lookupDepartmentByName('stage four - detail assignment');
				if (parseInt(dept_four.id) === parseInt(vehicle.department_id)) {
					department = this.props.lookupDepartmentByName('stage six - digital listings');
				}
			} else if ((key == 'on site - interior' || key == 'sublet detail') && (value === 'completed')) {
				const touch_up_complete_needed = this.vehicleProperty('on site - exterior and touch up');
				const anderson_auto_interior_needed = this.vehicleProperty('anderson auto interior - needed');
				const anderson_auto_interior = this.vehicleProperty('anderson auto interior');

				if (anderson_auto_interior_needed === 'true') {
					const data_interior = {
						vehicle_id,
						value: 'needed',
						key: 'anderson auto interior',
					};

					this.props.addVehicleProperty(data_interior, 'anderson auto interior');
				} else if (touch_up_complete_needed !== 'completed') {
					data_est = {
							vehicle_id,
							value: 'needed',
							key: 'on site - exterior and touch up',
						};

						this.props.addVehicleProperty(data_est, 'adding touch up');
				}
			} else if ((key == 'anderson auto interior') && (value === 'completed')) {
				const touch_up_complete_needed = this.vehicleProperty('on site - exterior and touch up');

				if (touch_up_complete_needed !== 'completed') {
					data_est = {
						vehicle_id,
						value: 'needed',
						key: 'on site - exterior and touch up',
					};

					this.props.addVehicleProperty(data_est, 'adding touch up');
				}
			}

			if (department.id) {
				this.props.updateVehicleDepartment(vehicle_id, 'department_id', parseInt(department.id), vehicle);
			}
		}
	}

	onChangeSelect(e) {
		this.setState({ value: e });
		const vehicle_id = this.props.parentid;
		const vehicle = this.props.vehicle;

		if (e !== this.props.value) {
			const errorType = 'updateVehicleError';
			const { rowid } = this.props;
			const key = this.props.name;
			const value = e;
			const uid = this.props.current_user.user;
			const user_name = this.props.current_user.user_name || this.props.current_user.email;

			if (this.props.updatetype == 'vehicleproperty') {
				const data = {
					vehicle_id,
					key,
					value,
					uid,
					user_name,
				};

				if (key === 'classification') {
					var data2 = {
						vehicle_id,
						value: `Classification - ${value}`,
						uid: this.props.current_user.user,
					};
					this.props.addVehicleNoteRaw(data2, 'addVehicleNoteError');
				} else if (key.includes('advisor') && key.includes(this.props.installation.subdomain)) {
					if (this.props.softname.includes('stanley')) {
						const data_estimate = {
							vehicle_id,
							ro_status: 'getapproved',
						};

						this.props.addEstimateRaw(data_estimate, 'addEstimateError');

						var data3 = {
							vehicle_id,
							value: 'pending',
							key: 'advisor status',
							uid: this.props.current_user.user,
						};
						this.props.addVehicleProperty(data3, 'advisor status');
					}
				} else if (key.includes('tech') && key.includes(this.props.installation.subdomain)) {
					if (this.props.softname.includes('stanley')) {
						var data3 = {
							vehicle_id,
							value: 'pending',
							key: 'tech status',
							uid: this.props.current_user.user,
						};
						this.props.addVehicleProperty(data3, 'tech status');
					}
				}

				Promise.all([
					this.props.addVehicleProperty(data, errorType),
				]).then(() => {
					const { vehicle_filter, department } = this.props;

					if (vehicle_filter.type === 'searched') {
						this.props.vsearch(vehicle_filter.value);
					} else {
						this.props.getCurrentDepartment(parseInt(department));
					}
				});
			} else if (this.props.updatetype == 'vehicle') {
				Promise.all([
					this.props.updateVehicle(vehicle_id, key, value),
				]).then(() => {
					const { vehicle_filter, department } = this.props;

					if (vehicle_filter.type === 'searched') {
						this.props.vsearch(vehicle_filter.value);
					} else {
						this.props.getCurrentDepartment(parseInt(department));
					}
				});
			}
		}
	}

	selectRelatedInstallations() {
		const options = this.props.related_installations.map((option) => {
			if (typeof option.softname !== 'undefined') {
				var obj = { value: option.subdomain, label: option.softname };
			} else {
				var obj = { value: option.subdomain, label: option.subdomain };
			}
			return obj;
		});
		return options;
	}

	render() {
		return (
			(() => {
				switch (this.props.type) {
				case 'select':
					return <Select
						titleHelper={''}
						title={'Choose'}
						onChange={this.onChangeSelect}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
						list={this.selectOptions(this.props.options, this.state.value)}
						update_type={'vehicle_body'}
						tabindex={this.props.tabindex}
					/>;

				case 'select-recipients':
					return <div style={{ width: '250px' }}>
						<SelectMultiple
							titleHelper={'Recipients'}
							title={'Choose'}
							onChange={this.onChangeSelect}
							value={this.state.value}
							type={this.props.type}
							name={this.props.name}
							estimate_id={this.props.estimate_id}
							parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
							className={classnames('form-control', { 'form-control-danger': this.props.error })}
							list={this.filterUsers(this.state.value)}
							update_type={'select-recipients'}
							tabindex={this.props.tabindex}
						/>
            </div>;

				case 'select-dealerships':
					return <Select
						onChange={this.onChangeSelect}
						name={this.props.name}
						value={this.selectRelatedInstallations().filter(({ value }) => value === this.state.value)}
						options={this.selectRelatedInstallations()}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						clearable={false}
						isDisabled={this.props.setdisabled}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						menuContainerStyle={{ zIndex: 5 }}
						className="text-capitalize"
						tabindex={this.props.tabindex}
					/>;

			case 'select-work-item':
				return <div style={{ width: '250px' }}>
					<Select
						titleHelper={'Type'}
						title={'Choose'}
						onChange={this.onChangeSelect}
						name={this.props.name}
						value={this.props.value}
						list={this.selectWorkItem()}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={'vehicleproperty'}
						clearable={false}
						isDisabled={this.props.setdisabled}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						menuContainerStyle={{ zIndex: 5 }}
						className="text-capitalize"
						update_type={'pay_type'}
						estimate_id={this.props.estimate_id}
						tabindex={this.props.tabindex}
					/>
			</div>;

			case 'pay_type':
								return <div style={{ width: '250px' }}>
									<Select
										titleHelper={'Type'}
										title={'Choose'}
										onChange={this.onChangeSelect}
										name={this.props.name}
										value={this.props.value}
										list={this.selectPayType()}
										data-id={this.props.rowid}
										data-parentid={this.props.parentid}
										data-updatetype={'vehicleproperty'}
										clearable={false}
										isDisabled={this.props.setdisabled}
										key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
										menuContainerStyle={{ zIndex: 5 }}
										className="text-capitalize"
										update_type={'pay_type'}
										estimate_id={this.props.estimate_id}
										tabindex={this.props.tabindex}
									/>
						</div>;

				case 'text':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
						placeholder={this.props.placeholder ? this.props.placeholder : ''}
						tabIndex={this.props.tabindex}
					/>;

				case 'checkbox':
					return <input
						onChange={this.onChangeChecked}
						checked={this.state.value == 'true' ? 'checked' : ''}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						tabIndex={this.props.tabindex}
					/>;

				case 'userstamp':
					var updateDate = userStamp(this.props.updated_at);
					var user_name = this.props.user_name ? this.props.user_name.split('@')[0] : '';

					return <div>
						<input
							onChange={this.onChangeChecked}
							checked={this.state.value == 'true' ? 'checked' : ''}
							type={'checkbox'}
							name={this.props.name}
							data-id={this.props.rowid}
							data-parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
							tabIndex={this.props.tabindex}
						/>
						<br />
						<small className="text-center">{this.props.value == 'true' ? user_name : ''}</small>
						<br />
						<small className="text-center">{this.props.value == 'true' ? updateDate : ''}</small>
            </div>;

				case 'userstamp-workitem':
					var updateDate = userStamp(this.props.updated_at);
					var user_name = this.props.user_name ? this.props.user_name.split('@')[0] : '';

					return <div>
						<input
							onChange={this.onChangeChecked}
							checked={this.state.value == 'true' ? 'checked' : ''}
							type={'checkbox'}
							name={this.props.name}
							data-id={this.props.rowid}
							data-parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
							tabIndex={this.props.tabindex}
						/>
						<br />
						<small className="text-center">{this.props.value == 'true' ? user_name : ''}</small>
						<br />
						<small className="text-center">{this.props.value == 'true' ? updateDate : ''}</small>
            </div>;

				case 'userstamp_text':
					var updateDate = userStamp(this.props.updated_at);
					var user_name = this.props.user_name ? this.props.user_name.split('@')[0] : '';

					return <div>
						<input
							onBlur={this.onBlur}
							onChange={this.onChange}
							value={this.state.value}
							type={this.props.type}
							name={this.props.name}
							data-id={this.props.rowid}
							data-parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
							className={classnames('form-control', { 'form-control-danger': this.props.error })}
							tabIndex={this.props.tabindex}
						/>
						<small className="text-center">{this.props.value ? user_name : ''}</small>
						<br />
						<small className="text-center">{this.props.value ? updateDate : ''}</small>
            </div>;

				case 'automove_check':
					var updateDate = userStamp(this.props.updated_at);
					var user_name = this.props.user_name ? this.props.user_name.split('@')[0] : '';

					return <div>
						<input
							onChange={this.onChangeChecked}
							checked={this.state.value == 'true' ? 'checked' : ''}
							type={'checkbox'}
							name={this.props.name}
							data-id={this.props.rowid}
							data-parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
							tabIndex={this.props.tabindex}
						/>
						<br />
						<small className="text-center">{this.props.value == 'true' ? user_name : ''}</small>
						<br />
						<small className="text-center">{this.props.value == 'true' ? updateDate : ''}</small>
            </div>;

				case 'switch':
					return <span className="switch">
						<input
							onChange={this.onChangeChecked}
							checked={this.state.value == 'true' ? 'checked' : ''}
							type={'checkbox'}
							name={this.props.name}
							data-id={this.props.rowid}
							data-parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
							id={`switch_id_${this.props.parentid}_${this.props.name}`}
							tabIndex={this.props.tabindex}
						/>
						<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.parentid}_${this.props.name}`}>{this.props.name.replace(/_/g, ' ')}</label>
					</span>;

				case 'switch-no-label':
					return <span className="switch">
						<input
							onChange={this.onChangeChecked}
							checked={this.state.value == 'true' ? 'checked' : ''}
							type={'checkbox'}
							name={this.props.name}
							data-id={this.props.rowid}
							data-parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
							id={`switch_id_${this.props.parentid}_${this.props.name}`}
							tabIndex={this.props.tabindex}
						/>
						<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.parentid}_${this.props.name}`} />
					</span>;

				case 'switch-with-description-invoice':
					var updateDate = userStamp(this.props.updated_at);
					var user_name = this.props.user_name ? this.props.user_name.split('@')[0] : '';

					return <table className="table table-striped">
						<thead>
							<tr>
								<th>Needed</th>
								<th>Completed</th>
								<th>Invoice #</th>
								<th>Description</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div className="switch">
										<input
											onChange={this.onChangeCheckedNeeded}
											checked={this.state.value == 'needed' ? 'checked' : ''}
											type={'checkbox'}
											name={this.props.name}
											data-id={this.props.rowid}
											data-parentid={this.props.parentid}
											data-updatetype={this.props.updatetype}
											disabled={this.props.setdisabled ? 'disabled' : ''}
											key={`${this.props.type}_${this.props.parentid}_needed${this.props.name}`}
											id={`switch_id_${this.props.parentid}_${this.props.name}`}
											tabIndex={this.props.tabindex}
										/>
										<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.parentid}_${this.props.name}`} />
										<div className="col-md-12 text-center">
											<small>{this.props.value == 'needed' ? user_name : ''}</small>
											<br />
											<small>{this.props.value == 'needed' ? updateDate : ''}</small>
										</div>
									</div>

								</td>
								<td>
									<div className="switch">
										<input
											onChange={this.onChangeCheckedCompleted}
											checked={this.state.value == 'completed' ? 'checked' : ''}
											type={'checkbox'}
											name={this.props.name}
											data-id={this.props.rowid}
											data-parentid={this.props.parentid}
											data-updatetype={this.props.updatetype}
											disabled={this.props.setdisabled ? 'disabled' : ''}
											key={`${this.props.type}_${this.props.parentid}_completed${this.props.name}`}
											id={`switch_id2_${this.props.parentid}_${this.props.name}`}
											tabIndex={this.props.tabindex}
										/>
										<label className={'text-capitalize'} htmlFor={`switch_id2_${this.props.parentid}_${this.props.name}`} />
										<div className="col-md-12 text-center">
											<small>{this.props.value == 'completed' ? user_name : ''}</small>
											<br />
											<small>{this.props.value == 'completed' ? updateDate : ''}</small>
										</div>
									</div>

								</td>
								<td>
									<div>
												<input
													onBlur={this.onBlur}
													onChange={this.onChange}
													value={this.state.value}
													type={this.props.type}
													name={this.props.name}
													data-id={this.props.rowid}
													data-parentid={this.props.parentid}
													data-updatetype={this.props.updatetype}
													disabled={this.props.setdisabled ? 'disabled' : ''}
													key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
													className={classnames('form-control', { 'form-control-danger': this.props.error })}
													tabIndex={this.props.tabindex}
												/>
												<small className="text-center">{this.props.value ? user_name : ''}</small>
												<br />
												<small className="text-center">{this.props.value ? updateDate : ''}</small>
						            </div>

								</td>
								<td>
									<div><textarea
											onBlur={this.onBlur}
											onChange={this.onChange}
											value={this.state.value}
											type={this.props.type}
											name={this.props.name}
											data-id={this.props.rowid}
											data-parentid={this.props.parentid}
											data-updatetype={this.props.updatetype}
											disabled={this.props.setdisabled ? 'disabled' : ''}
											tabIndex={this.props.tabindex}
											key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
											cols={50}
											className={classnames('form-control', { 'form-control-danger': this.props.error })}
									/>
									</div>

								</td>
								</tr>
							</tbody>
            			</table>;

				case 'switch-no-label-parts':
					return <span className="switch">
						<input
							onChange={this.onChangeChecked}
							checked={this.state.value === true ? 'checked' : ''}
							type={'checkbox'}
							name={this.props.name}
							data-id={this.props.rowid}
							data-parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							key={`${this.props.type}_${this.props.part_order_id}_${this.props.name}`}
							id={`switch_id_${this.props.part_order_id}_${this.props.name}`}
							tabIndex={this.props.tabindex}
						/>
						<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.part_order_id}_${this.props.name}`} />
					</span>;

				case 'switch-userstamp':
					var updateDate = userStamp(this.props.updated_at);
					var user_name = this.props.user_name ? this.props.user_name.split('@')[0] : '';
					return <div className="switch">
						<input
							onChange={this.onChangeChecked}
							checked={this.state.value == 'true' ? 'checked' : ''}
							type={'checkbox'}
							name={this.props.name}
							data-id={this.props.rowid}
							data-parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
							id={`switch_id_${this.props.parentid}_${this.props.name}`}
							tabIndex={this.props.tabindex}
						/>
						<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.parentid}_${this.props.name}`} />
						<div className="col-md-12">
							<small>{this.props.value == 'true' ? user_name : ''}</small>
							<br />
							<small>{this.props.value == 'true' ? updateDate : ''}</small>
						</div>
            </div>;

				case 'switch-needed':
					var updateDate = userStamp(this.props.updated_at);
					var user_name = this.props.user_name ? this.props.user_name.split('@')[0] : '';
					return <table className="table table-striped">
						<thead>
							<tr>
								<th>{this.props.display_label_1 !== '' ? this.props.display_label_1 : 'Needed' }</th>
								<th>{this.props.display_label_2 !== '' ? this.props.display_label_2 : 'Completed' }</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="desk-w-30">
									<div className="switch">
										<input
											onChange={this.onChangeCheckedNeeded}
											checked={this.state.value == 'needed' ? 'checked' : ''}
											type={'checkbox'}
											name={this.props.name}
											data-id={this.props.rowid}
											data-parentid={this.props.parentid}
											data-updatetype={this.props.updatetype}
											disabled={this.props.setdisabled ? 'disabled' : ''}
											key={`${this.props.type}_${this.props.parentid}_needed${this.props.name}`}
											id={`switch_id_${this.props.parentid}_${this.props.name}`}
											tabIndex={this.props.tabindex}
										/>
										<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.parentid}_${this.props.name}`} />
										<div className="col-md-12 text-center">
											{(this.state.value== 'needed' && user_name)?
												<React.Fragment>
													<small>{this.state.value == 'needed' ? user_name : ''}</small>
													<br />
												</React.Fragment>
												: <React.Fragment/>
											}
											{(this.state.value== 'needed' && user_name)?
												<React.Fragment>
													<small>{this.state.value == 'needed' ? updateDate : ''}</small>
												</React.Fragment>
												: <React.Fragment/>
											}

										</div>
									</div>

								</td>
								<td>
									<div className="switch">
										<input
											onChange={this.onChangeCheckedCompleted}
											checked={this.state.value == 'completed' ? 'checked' : ''}
											type={'checkbox'}
											name={this.props.name}
											data-id={this.props.rowid}
											data-parentid={this.props.parentid}
											data-updatetype={this.props.updatetype}
											disabled={this.props.setdisabled ? 'disabled' : ''}
											key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
											id={`switch_id2_${this.props.parentid}_${this.props.name}`}
											tabIndex={this.props.tabindex}
										/>
										<label className={'text-capitalize'} htmlFor={`switch_id2_${this.props.parentid}_${this.props.name}`} />
										<div className="col-md-12 text-center">
											{(this.state.value== 'completed' && user_name)?
												<React.Fragment>
													<small>{this.state.value == 'completed' ? user_name : ''}</small>
													<br />
												</React.Fragment>
												: <React.Fragment/>
											}
											{(this.state.value== 'completed' && user_name)?
												<React.Fragment>
													<small>{this.state.value == 'completed' ? updateDate : ''}</small>
												</React.Fragment>
												: <React.Fragment/>
											}
										</div>
									</div>

								</td>
							</tr>
						</tbody>
            </table>;

				case 'switch-needed-nolabel':
					var updateDate = userStamp(this.props.updated_at);
					var user_name = this.props.user_name ? this.props.user_name.split('@')[0] : '';

					return <table className="table table-striped">
						<thead>
							<tr>
								<th>Needed</th>
								<th>Completed</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div className="switch">
										<input
											onChange={this.onChangeCheckedNeeded}
											checked={this.state.value == 'needed' ? 'checked' : ''}
											type={'checkbox'}
											name={this.props.name}
											data-id={this.props.rowid}
											data-parentid={this.props.parentid}
											data-updatetype={this.props.updatetype}
											disabled={this.props.setdisabled ? 'disabled' : ''}
											key={`${this.props.type}_${this.props.parentid}_needed${this.props.name}`}
											id={`switch_id_${this.props.parentid}_${this.props.name}`}
											tabIndex={this.props.tabindex}
										/>
										<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.parentid}_${this.props.name}`} />
										<div className="col-md-12 text-center">
											<small>{this.props.value == 'needed' ? user_name : ''}</small>
											<br />
											<small>{this.props.value == 'needed' ? updateDate : ''}</small>
										</div>
									</div>

								</td>
								<td>
									<div className="switch">
										<input
											onChange={this.onChangeCheckedCompleted}
											checked={this.state.value == 'completed' ? 'checked' : ''}
											type={'checkbox'}
											name={this.props.name}
											data-id={this.props.rowid}
											data-parentid={this.props.parentid}
											data-updatetype={this.props.updatetype}
											disabled={this.props.setdisabled ? 'disabled' : ''}
											key={`${this.props.type}_${this.props.parentid}_completed${this.props.name}`}
											id={`switch_id2_${this.props.parentid}_${this.props.name}`}
											tabIndex={this.props.tabindex}
										/>
										<label className={'text-capitalize'} htmlFor={`switch_id2_${this.props.parentid}_${this.props.name}`} />
										<div className="col-md-12 text-center">
											<small>{this.props.value == 'completed' ? user_name : ''}</small>
											<br />
											<small>{this.props.value == 'completed' ? updateDate : ''}</small>
										</div>
									</div>

								</td>
							</tr>
						</tbody>
            </table>;

			case 'switch-needed-custom-labels':
				var updateDate = userStamp(this.props.updated_at);
				var user_name = this.props.user_name ? this.props.user_name.split('@')[0] : '';
				return <table className="table table-striped">
					<thead>
						<tr>
							<th />
							<th>{this.props.label1}</th>
							<th>{this.props.label2}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th className="text-capitalize desk-w-30">{this.props.name}</th>
							<td className="desk-w-30">
								<div className="switch">
									<input
										onChange={this.onChangeCheckedNeeded}
										checked={this.state.value == 'needed' ? 'checked' : ''}
										type={'checkbox'}
										name={`${this.props.name}`}
										data-id={this.props.rowid}
										data-parentid={this.props.parentid}
										data-updatetype={this.props.updatetype}
										disabled={this.props.setdisabled ? 'disabled' : ''}
										key={`${this.props.type}_${this.props.parentid}_needed${this.props.name}`}
										id={`switch_id_${this.props.parentid}_${this.props.name}`}
										tabIndex={this.props.tabindex}
									/>
									<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.parentid}_${this.props.name}`} />
									<div className="col-md-12 text-center">
										<small>{this.state.value == 'needed' ? user_name : ''}</small>
										<br />
										<small>{this.state.value == 'needed' ? updateDate : ''}</small>
									</div>
								</div>

							</td>
							<td>
								<div className="switch">
									<input
										onChange={this.onChangeCheckedCompleted}
										checked={this.state.value == 'completed' ? 'checked' : ''}
										type={'checkbox'}
										name={this.props.name}
										data-id={this.props.rowid}
										data-parentid={this.props.parentid}
										data-updatetype={this.props.updatetype}
										disabled={this.props.setdisabled ? 'disabled' : ''}
										key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
										id={`switch_id2_${this.props.parentid}_${this.props.name}`}
										tabIndex={this.props.tabindex}
									/>
									<label className={'text-capitalize'} htmlFor={`switch_id2_${this.props.parentid}_${this.props.name}`} />
									<div className="col-md-12 text-center">
										<small>{this.state.value == 'completed' ? user_name : ''}</small>
										<br />
										<small>{this.state.value == 'completed' ? updateDate : ''}</small>
									</div>
								</div>

							</td>
						</tr>
					</tbody>
			</table>;

				case 'age':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'approved':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'color':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'cost':
					return <div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text">$</span>
						</div>
						<input
							onBlur={this.onBlur}
							onChange={this.onChange}
							value={this.state.value}
							type={this.props.type}
							name={this.props.name}
							data-id={this.props.rowid}
							data-parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							tabIndex={this.props.tabindex}
							key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
							className={classnames('form-control', { 'form-control-danger': this.props.error })}
						/>
            </div>;

				case 'date':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={getDayMonthYearFromDate(this.state.value) || ''}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'days':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'department_extern':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'displayonly':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'dollar':
					return <div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text">$</span>
						</div>
						<input
							onBlur={this.onBlur}
							onChange={this.onChange}
							value={this.state.value}
							type={'number'}
							name={this.props.name}
							data-id={this.props.rowid}
							data-parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							tabIndex={this.props.tabindex}
							key={`${this.props.parentid}_${this.props.name}`}
							className={classnames('form-control', { 'form-control-danger': this.props.error })}
						/>
					</div>;

				case 'extern':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'external':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'estimate_files':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'icon':
					return <div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text"><i className={`fa fa-${this.props.icon}`} /></span>
						</div>
						<input
							onBlur={this.onBlur}
							onChange={this.onChange}
							value={this.state.value}
							type={this.props.type}
							name={this.props.name}
							data-id={this.props.rowid}
							data-parentid={this.props.parentid}
							data-updatetype={this.props.updatetype}
							disabled={this.props.setdisabled ? 'disabled' : ''}
							tabIndex={this.props.tabindex}
							key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
							className={classnames('form-control', { 'form-control-danger': this.props.error })}
						/>
            </div>;

				case 'month':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'number':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'radio':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'range':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'textarea':
					return <textarea
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						cols={50}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'textarea-big':
					return <textarea
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						tabIndex={this.props.tabindex}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						cols={50}
						rows={5}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'thumbnail':
					return <img
						src={this.props.value}
						className={'img-thumbnail'}
					/>;

				case 'time':
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						tabIndex={this.props.tabindex}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				default:
					return <input
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={this.props.type}
						name={this.props.name}
						data-id={this.props.rowid}
						data-parentid={this.props.parentid}
						data-updatetype={this.props.updatetype}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						key={`${this.props.type}_${this.props.parentid}_${this.props.name}`}
						tabIndex={this.props.tabindex}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;
				}
			})()

		);
	}
}

const mappedActions = {
	lookupDepartmentByName,
	lookupRecipients,
	addVehicleNote,
	addVehicleNoteRaw,
	addVehicleProperty,
	updateVehicle,
	updateVehicleData,
	addVehiclePropertyRaw,
	updateEstimate,
	sendEstimate,
	sendEstimateRaw,
	updatePartOrder,
	updateVehicleDepartment,
	addEstimate,
	addEstimateRaw,
	addPartOrder,
	addPartOrderRaw,
	addMehanicalNotification,
	vsearch,
	processDepartments,
	updateWorkItem,
	getCurrentDepartment,
	refreshDepartment,
	activeVehicle,
	lookupVehicleColumnById,
	setVehicleTab,
};

function mapStateToProps(state) {
	return {
		input_types: state.settings_departments.input_types,
		current_user: state.current_user,
		current_dealership: state.settings_dealerships.current_dealership,
		related_installations: state.settings_dealerships.related_installations,
		softname: state.settings_dealerships.installation.softname,
		installation: state.settings_dealerships.installation,
		notification_settings: state.settings_dealerships.notification_settings,
		vehicle_filter: state.settings_nav.vehicle_filter,
		department: state.settings_nav.department,
		vehicle_tab: state.settings_nav.menus.vehicle_tab,
	};
}
export default connect(mapStateToProps, mappedActions)(AllInputs);
