import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	toggleSiteMap,
	toggleExpandAllPlus,
	toggleHideBlank,
	toggleSortDescending,
	toggleWorkFlow,
	toggleSidebar,
} from '../../actions/toggleMenu';
import {
	refreshDepartment,
} from '../../actions/apiGetInfo';

class SidebarListButton extends Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	onClick() {
		switch (this.props.name) {
		case 'menu-sitemap':
			Promise.all([
				this.props.toggleSiteMap(),
			]).then(() => this.props.refreshDepartment(this.props.current_department.id));
			break;
		case 'menu-expandall':
			Promise.all([
				this.props.toggleExpandAllPlus(),
			]).then(() => this.props.refreshDepartment(this.props.current_department.id));
			break;
		case 'menu-hideblank':
			Promise.all([
				this.props.toggleHideBlank(),
			]).then(() => this.props.refreshDepartment(this.props.current_department.id));
			break;
		case 'menu-descending':
			Promise.all([
				this.props.toggleSortDescending(),
			]).then(() => this.props.refreshDepartment(this.props.current_department.id));
			break;
		case 'menu-workflow':
			Promise.all([
				this.props.toggleWorkFlow(this.props.workflow),
			]).then(() => this.props.refreshDepartment(this.props.current_department.id));
			break;
		case 'menu-togglesidebar':
			this.props.toggleSidebar();
			break;
		default:
			break;
		}
	}

	render() {
		let btnStyle = 'info';
		let isCurrentWorkflow = false;
		let applyCustomizations = false;
		switch (this.props.name) {
		case 'menu-sitemap':
			applyCustomizations = true;
			btnStyle = 'light';
			break;
		case 'menu-expandall':
			btnStyle = 'success';
			break;
		case 'menu-hideblank':
			applyCustomizations = true;
			btnStyle = 'light';
			break;
		case 'menu-descending':
			btnStyle = 'success';
			break;
		case 'menu-workflow':
			applyCustomizations = true;
			if (this.props.workflow == this.props.work_flow) {
				btnStyle = 'success';
				isCurrentWorkflow = true;
			} else {
				btnStyle = 'light';
			}
			break;
		default:
			break;
		}

		let sidebarStyle = {};
		if (applyCustomizations && !isCurrentWorkflow) {
			sidebarStyle = {
				background: this.dealerSettingValue('color_sidebar') || undefined,
				color: this.dealerSettingValue('color_sidebar_text') || undefined,
			};
		}

		return (
			<button style={sidebarStyle} title={this.props.title} className={`btn btn-sm btn-${btnStyle}`} onClick={this.onClick}>
				{this.props.text}
				{' '}
				{this.props.workflow ? '' : <i className={this.props.icon} />}
			</button>
		);
	}
}

const mappedActions = {
	toggleExpandAllPlus,
	toggleHideBlank,
	toggleSiteMap,
	toggleSortDescending,
	toggleWorkFlow,
	refreshDepartment,
	toggleSidebar,
};

function mapStateToProps(state) {
	return {
		site_map: state.settings_nav.menus.site_map,
		expand_all: state.settings_nav.menus.expand_all,
		hide_blank: state.settings_nav.menus.hide_blank,
		work_flow: state.settings_nav.menus.work_flow,
		dealer_settings: state.settings_site.dealer_settings,
		current_department: state.settings_departments.department,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps, mappedActions)(SidebarListButton);
