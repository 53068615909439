import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';

class SiteSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value1: this.props.override_value_1, current_default_profile: this.props.current_default_profile, errors: { addDepartmentPropertyError: false }, option_name: '',
		};
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	render() {
		const { installation } = this.props;

		return (
			<div className="col-md-12">

				<div className="card space-top-0 v80">

					<h3 className="text-center pad-top-1">Preferences</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="row">
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'switch'} name="default_asc" label="Default: Sort Ascending" value={this.dealerSettingValue('default_asc')} />
							</div>
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'switch'} name="default_collapse_vehicles" label="Default: Collapse Vehicles" value={this.dealerSettingValue('default_collapse_vehicles')} />
							</div>
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'switch'} name="default_hide_empty_queues" label="Default: Hide Empty Queues" value={this.dealerSettingValue('default_hide_empty_queues')} />
							</div>
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'switch'} name="default_enable_dropdown_movement_profiles" label="Default: Enable Dropdown Movement Profiles" value={this.dealerSettingValue('default_enable_dropdown_movement_profiles')} />
							</div>

						</div>
					</div>

				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps)(SiteSettings);
