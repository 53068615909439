import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
	getSecondsFromTimeRange,
	returnAgeSeconds,
	readableStamp,
} from '../../../../utils/dateFunctions';
import {
	lookupUserById,
} from '../../../../actions/lookups';

class VehicleDataHistorySummarySingle extends PureComponent {
	department(id) {
		const self = this;
		const department = _.sortBy(_.filter(self.props.all_departments, o => o.id == id), 'id');
		return department[0] || [];
	}

	render() {
		const self = this;
		var totaltime = 0;
		const { department_history } = this.props;

		if (department_history.recon_total_24_5 > 0) {
			if (department_history.recon_total_24_5 > (24 * 60 * 60)) {
				totaltime = department_history.recon_total_24_5 / (24 * 60 * 60);
				var units = ' days';
			} else {
				var units = ' hours';
				totaltime = department_history.recon_total_24_5 / (60 * 60);
			}
		} else if (department_history.current === true) {
			var totaltime = department_history.recon_total_24_5;
			if (totaltime > (24 * 60 * 60)) {
				totaltime /= (24 * 60 * 60);
				var units = ' days';
			} else {
				var units = ' hours';
				totaltime /= (60 * 60);
			}
		} else {
			var totaltime = department_history.recon_total_24_5;
			if (totaltime > (24 * 60 * 60)) {
				totaltime /= (24 * 60 * 60);
				var units = ' days';
			} else {
				var units = ' hours';
				totaltime /= (60 * 60);
			}
		}

		const user_id = self.props.lookupUserById(department_history.recon_entered_by);
		const user_id_completed = self.props.lookupUserById(department_history.recon_ended_by);
		const user_name = user_id !== undefined ? user_id.user_name : 'ReconAdvisor';
		const unit = <span className="float-right">
			{totaltime.toFixed(1)}
			{' '}
			{units}
               </span>;
		return (
			<li key={`timeline_single_${department_history.id}`}>
				<div className="timeline-badge"><i className="fas fa-check-circle" /></div>
				<div className="timeline-panel bg-light box">
					<div className="timeline-heading">
						<h4 className="timeline-title text-capitalize">
							{self.department(department_history.department_id).name}
							{' '}
							{unit}
						</h4>
						<p>
							<small className="text-muted">
								<i className="glyphicon glyphicon-time" />
                                        Initiated By:
								{' '}
								<span className="text-primary">{readableStamp(department_history.recon_starttime)}</span>
								{' '}
by
								<span className="text-success">{user_name}</span>
							</small>
							<br />
							<small className="text-muted">
								<i className="glyphicon glyphicon-time" />
                                        Completed By:
								{' '}
								<span className="text-primary">{readableStamp(department_history.recon_endtime)}</span>
								{' '}
by
								<span className="text-success">{user_id_completed._user_name}</span>
							</small>
						</p>
					</div>
					<div className="timeline-body" />
				</div>

			</li>
		);
	}
}

const mappedActions = {
	lookupUserById,
};

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
		all_departments: state.settings_departments.original_departments,
		active_vehicle: state.settings_refresh.active_vehicle,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps, mappedActions)(VehicleDataHistorySummarySingle);
