import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import TextFieldGroup from '../../inputs/TextFieldGroupNoLabel';
import TextFieldGroupWithButton from '../../inputs/TextFieldGroupWithButton';
import TextBox from '../../inputs/TextBox';
import FlashMessageList from '../../inputs/FlashMessageList';
import Api from '../../api/reconApi.js';
import {
	addRoom, openSupportTicket, closeSupportTicket, addSupportTicket,
} from '../../actions/apiSupportTickets';

import {
	addFlashMessage,
} from '../../actions/toggleMenu';

class AddSupportTicket extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			isLoading: '',
			user_id: this.props.current_user.user,
			installation_id: this.props.current_dealership_id,
			errors: { AddSupportTicketError: false },
			group_name: '',
			agent_id: '',
			status: 'open',
			priority: 0,
			include_emails: '',
			feedback: '',
			agent_email: '',
			display_all: '',
			last_updated: '',
			last_updated_by: '',
			initial_issue: '',
		};
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChange = this.onChange.bind(this);
		this.addSupportTicket = this.addSupportTicket.bind(this);
	}

	openModal() {
		this.props.openSupportTicket();
	}

	afterOpenModal() {}

	closeModal() {
		this.props.closeSupportTicket();
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	department(id) {
		const self = this;
		const department = _.sortBy(_.filter(self.props.departments, o => o.id == id), 'id');
		return department[0].name || [];
	}

	addSupportTicket() {
		const self = this;
		const { stock } = this.state;
		const age = Date.now() / 1000;
		const data = {
			user_id: this.state.user_id,
			installation_id: this.state.installation_id,
			group_name: this.state.group_name,
			agent_id: this.state.agent_id,
			status: this.state.status,
			priority: this.state.priority,
			include_emails: this.state.include_emails,
			feedback: this.state.feed_back,
			agent_email: this.state.agent_email,
			display_all: this.state.display_all,
			last_updated: this.state.last_updated,
			last_updated_by: this.state.last_updated_by,
			initial_issue: this.state.initial_issue,
		};

		const extra = {};

		this.props.addSupportTicket(data, extra).then((resp) => {
			this.props.addFlashMessage({
				type: 'success',
				text: 'Support Ticket Has Been Created',
			});
			this.setState({ isLoading: false });
			setTimeout(() => {
				self.setState({
					user_id: this.state.user_id,
					installation_id: this.state.installation_id,
					group_name: '',
					agent_id: '',
					status: '',
					priority: 0,
					include_emails: '',
					feedback: '',
					agent_email: '',
					display_all: '',
					last_updated: '',
					last_updated_by: '',
					initial_issue: '',
					modalIsOpen: false,
				});
			}, 3000);
		});
	}

	onChange(event) {
		const { name } = event.target;

		this.setState({ [name]: event.target.value });
	}

	render() {
		const { errors } = this.state;

		return (
			<div className="col-md-12">
				<div className="col-md-12 text-center">
					<button className={this.props.support_ticket_widget === true ? 'btn btn-info openChatBtn chat-closed text-center' : 'btn btn-info openChatBtn chat-open text-center'} onClick={this.openModal}>
						<i className="fal fa-comment" />
						{' '}
Create Ticket
					</button>
				</div>
				<div className={this.props.support_ticket_widget === true ? 'container pad-left-0 pad-right-0 popup-box chat-open' : 'popup-box chat-closed'}>
					<div className="card pad-left-0 pad-right-0 pad-bottom-0">
						<div className="modal-header bg-dark text-white pad-top-0 pad-bottom-0">
							<h4 className="modal-title"><img key="toplogo" src="https://cdn.ipacketrecon.com/images/logo_40_white.png" key="support-chat" name="support-chat" style={{ marginTop: 5 }} height={30} width={157} /></h4>
							<button type="button" className="btn btn-danger btn-sm text-white" data-dismiss="modal" onClick={this.closeModal}>&times;</button>
						</div>

						<div className="modal-body add-vehicle">
							<div className="row">
								<label>Create Ticket with Support: </label>
								<div className="col-md-12"><p className="text-center"><small className="pad-top-1">Please enter a description and click "Create Support Ticket". Your ticket status will be available in the support tab above.</small></p></div>
								<div className="col-md-12">
									<TextBox
										onChange={this.onChange}
										field={'initial_issue'}
										value={this.state.initial_issue}
										type={'text'}
										name={'initial_issue'}
										updatetype="room"
										disabled={false}
									/>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-success" onClick={this.addSupportTicket}>Create Support Ticket</button>
						</div>
					</div>

				</div>

			</div>
		);
	}
}

const mappedActions = {
	addSupportTicket,
	addFlashMessage,
	openSupportTicket,
	closeSupportTicket,
};

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		current_dealership_id: state.settings_dealerships.current_dealership_id,
		support_ticket_widget: state.settings_support_tickets.support_ticket_widget,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps, mappedActions)(AddSupportTicket);
