import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';

class IncomingStepDefaults extends Component {
	constructor(props) {
		super(props);

		this.state = { current_default_profile: this.props.current_default_profile, errors: { addDepartmentPropertyError: false }, option_name: '' };
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	render() {
		return (
			<div className="col-md-12">

				<div className="card space-top-0 ">

					<h3 className="text-center pad-top-1">Incoming Step Defaults</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-body">
							<EditDealershipSettings {...this.props} type={'select-departments'} name="wholesale_car_default_department" label="Wholesale Default Department" value={this.dealerSettingValue('wholesale_car_default_department')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="transit_car_default_department" label="In Transit Default Department" value={this.dealerSettingValue('transit_car_default_department')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="prep_car_default_department" label="Prep Default Department" value={this.dealerSettingValue('prep_car_default_department')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="new_car_default_department" label="New Car Default Department" value={this.dealerSettingValue('new_car_default_department')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="used_car_default_department" label="Used Car Default Department" value={this.dealerSettingValue('used_car_default_department')} />

						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return { 
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	 };
}
export default connect(mapStateToProps)(IncomingStepDefaults);
