import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import _ from 'lodash';
import AllInputs from '../../../../inputs/TextFieldGroup';
import TabIcon from './TabIcon';
import {
	lookupUserById,
} from '../../../../actions/lookups';
import {
	setVehicleTab,
	setPhotos,
	setDepartmentHistories,
	setCustomModalTab,
} from '../../../../actions/apiSetInfo';
import {
	addVehicleProperty,
} from '../../../../actions/AllUpdates';
import {
	getPhotosByVid,
} from '../../../../actions/apiGetInfo';
import {
	returnAgeSeconds,
	getSecondsFromTimeRange,
	convertSecondsToTime,
	returnAgeFromTimestamp,
	numberWithCommas,
	returnHoursFromTimestamp,
	returnAgeFromDepartmentHistories,
	returnAgeFromDepartmentHistories_24_7,
	getSecondsFromTimeRange_24_6,
	getSecondsFromTimeRange_24_5,
	returnTabAge,
	returnTabAge_24_5,
	returnTabAge_24_6,
	returnTabAge_24_7,
	noteStamp,
} from '../../../../utils/dateFunctions';
import './TabLink.scss'
const customStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	},
	content: {
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
	},
};

class TabLink extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			BR: '', BL: '', FL: '', FR: '', 'rims size': '', tabstyle: {},
		};
		this.onTabClick = this.onTabClick.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.dealerSettingValue = this.dealerSettingValue.bind(this);
		this.vehicleProperty = this.vehicleProperty.bind(this);
	}

	vehicleProperty(name) {
		const self = this;
		const vehicle = this.props.vehicle || [];
		const value = _.filter(vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	onTabClick() {
		if (this.props.name !== 'tab_checkboxes' && this.props.isCurrentlySelectedTab) {
			this.props.onTabClick();
			return;
		}
		if (this.props.name === 'tab_checkboxes') {
			this.props.setVehicleTab(this.props.name, this.props.vehicle.id);
		} else if (this.props.name === 'tab_pictures') {
			this.props.setPhotos({ photos: [] });
			this.props.getPhotosByVid(this.props.vehicle.id);
			this.props.setVehicleTab(this.props.name, this.props.vehicle.id);
		} else if (this.props.name === 'tab_history') {
			this.props.setVehicleTab(this.props.name, this.props.vehicle.id);
		} else if (this.props.name === 'tab_mechanical') {
			this.props.setVehicleTab(this.props.name, this.props.vehicle.id);
		} else if (this.props.name === 'tab_estimate' || this.props.name === 'tab_vendor') {
			this.props.setVehicleTab(this.props.name, this.props.vehicle.id);
		} else if (this.props.name === 'tab_recall') {
			this.props.setVehicleTab(this.props.name, this.props.vehicle.id);
		} else if (this.props.name === 'tab_parts_order') {
			this.props.setVehicleTab(this.props.name, this.props.vehicle.id);
		} else {
			this.props.setVehicleTab(this.props.name, this.props.vehicle.id);
		}
	}

	openModal() {
		this.props.setCustomModalTab(this.props.tab, this.props.vehicle.id, this.props.vehicle, true);
	}

	afterOpenModal() {
	}

	closeModal(e) {
		e.stopPropagation();
		this.setState({ modalIsOpen: false });
		console.log('test mod');
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	returnTimeDisplay(time_tab) {
		const vehicle = this.props.vehicle || [];
		const { completed_departments } = vehicle;
		const work_flows = this.props.work_flows;
		const { tab } = this.props;
		let vehicle_workflow = '';
		let plus_seconds = 0;
		let work_flow_properties = [];
		const end = new Date();
		let seconds = 0;
		const defaultStyle = {
			background: tab.icon_bg_color || '',
			backgroundColor: tab.icon_bg_color || '',
			color: tab.icon_font_color || '#FFF',
			borderRadius: '.4em,.4em,0,0',
			padding: '1px',
		};

		const pendingStyle = {
			background: tab.icon_pending_bg_color,
			backgroundColor: tab.icon_pending_bg_color,
			color: tab.icon_pending_font_color,
			borderRadius: '.4em,.4em,0,0',
			padding: '1px',
		};

		const successStyle = {
			background: tab.icon_success_bg_color,
			backgroundColor: tab.icon_success_bg_color,
			color: tab.icon_success_font_color,
			borderRadius: '.4em,.4em,0,0',
			padding: '1px',
		};

		const warningStyle = {
			background: tab.icon_warning_bg_color,
			backgroundColor: tab.icon_warning_bg_color,
			color: tab.icon_warning_font_color,
			borderRadius: '.4em,.4em,0,0',
			padding: '1px',
		};

		switch (vehicle.stocktype?vehicle.stocktype.toUpperCase():'') {
		case 'N':
			vehicle_workflow = this.dealerSettingValue('recon_new_work_flow');
			break;
		case 'U':
			vehicle_workflow = this.dealerSettingValue('recon_used_work_flow');
			break;
		case 'W':
			vehicle_workflow = this.dealerSettingValue('recon_wholesale_work_flow');
			break;
		default:
			vehicle_workflow = '';
		}

		if (vehicle_workflow !== '') {
			const work_flow = _.find(work_flows, { name: vehicle_workflow }) || '';

			if (work_flow && work_flow.work_flow_properties.length > 0) {
				work_flow_properties = work_flow.work_flow_properties;
			}
		}

		switch (time_tab) {
		case 'age-24_7':
			seconds = getSecondsFromTimeRange(vehicle.created_at, end);
			break;
		case 'age-24_6':
			seconds = getSecondsFromTimeRange_24_6(vehicle.created_at, end);
			break;
		case 'age-24_5':
			seconds = getSecondsFromTimeRange_24_5(vehicle.created_at, end);
			break;
		case 'age-recononly-24_7':
			plus_seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			seconds = returnTabAge_24_7(completed_departments, work_flow_properties);
			break;
		case 'age-recononly-24_6':
			plus_seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
			seconds = returnTabAge_24_6(completed_departments, work_flow_properties);
			break;
		case 'age-recononly-24_5':
			plus_seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
			seconds = returnTabAge_24_5(completed_departments, work_flow_properties);
			break;
		case 'department-24_7':
			seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			break;
		case 'department-24_6':
			seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
			break;
		case 'department-24_5':
			seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
			break;
		case 'department-instance-24_7':
			seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			break;
		case 'department-instance-24_6':
			seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
			break;
		case 'department-instance-24_5':
			seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
			break;
		default:
			seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			break;
		}

		let days = 0;
		days = seconds + plus_seconds;

		if (days > (24 * 60 * 60)) {
			if (days > (24 * 60 * 60 * 365)) {
				var time = 0;
			} else {
				var time = (days / (24 * 60 * 60));
				time = `${parseInt(time)} days`;
			}
		} else {
			var time = (days / (60 * 60));
			time = `${parseInt(time)} hrs`;
		}
		return time;
	}

	render() {
		const self = this;
		const { vehicle, hideColorIndicator } = this.props;
		const { tab } = this.props;
		const end = new Date();
		let automax = '';
		let automax2 = '';
		const defaultStyle = {
			background: tab.icon_bg_color || '',
			backgroundColor: tab.icon_bg_color || '',
			color: tab.icon_font_color || '#FFF',
			borderRadius: '.4em,.4em,0,0',
			padding: '1px',
		};

		const pendingStyle = {
			background: tab.icon_pending_bg_color,
			backgroundColor: tab.icon_pending_bg_color,
			color: tab.icon_pending_font_color,
			borderRadius: '.4em,.4em,0,0',
			padding: '1px',
		};

		const availableStyle = {
			background: 'yellow',
			backgroundColor: 'yellow',
			color: tab.icon_pending_font_color,
			borderRadius: '.4em,.4em,0,0',
			padding: '1px',
		};

		const successStyle = {
			background: tab.icon_success_bg_color,
			backgroundColor: tab.icon_success_bg_color,
			color: tab.icon_success_font_color,
			borderRadius: '.4em,.4em,0,0',
			padding: '1px',
		};

		const warningStyle = {
			background: tab.icon_warning_bg_color,
			backgroundColor: tab.icon_warning_bg_color,
			color: tab.icon_warning_font_color,
			borderRadius: '.4em,.4em,0,0',
			padding: '1px',
		};
		var tabStyle = defaultStyle;

		const icons = [];
		const pendingCount = 0;
		const successCount = 0;
		const warningCount = 0;

		if (typeof tab !== 'undefined') {
			if (tab.name === 'tab_checkboxes') {
				if (self.props.installation.subdomain.includes('automaxatlanta')) {
					const wheel_array = [{ name: 'wheel repair' }, { name: 'Wheel Repair FL' }, { name: 'Wheel Repair FR' }, { name: 'Wheel Repair BL' }, { name: 'Wheel Repair BR' }];
					automax = (
						wheel_array.map((tab_property) => {
							const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name });

							if (typeof vehicle_column !== 'undefined') {
								const colObject = _.find(vehicle.vehicle_properties, { key: vehicle_column.name }) || '';

								return <span>
									<label className="space-top-1">{tab_property.name}</label>
									<AllInputs
										name={vehicle_column.name}
										label={vehicle_column.name}
										onChange={this.onChange}
										onBlur={this.onBlur}
										username={colObject.user_name}
										value={this.state[tab_property.name] || colObject.value || ''}
										type={vehicle_column.input_name}
										options={vehicle_column.options}
										checked={colObject.value || ''}
										updatetype={'vehicleproperty'}
										key={`vehicle_${vehicle.id}tab_property${colObject.id}`}
										parentid={vehicle.id}
										rowid={colObject.id || ''}
										uid={colObject.uid}
										updated_at={colObject.updated_at}
										icon={vehicle_column.icon}
										userStamp={this.props.userStamp}
										setdisabled={false}
										vehicle={vehicle}
									/>
								</span>;
							}
						})

					);

					const tire_array = [{ name: 'tires' }, { name: 'Tires FL' }, { name: 'Tires FR' }, { name: 'Tires BL' }, { name: 'Tires BR' }];
					automax2 = (
						tire_array.map((tab_property) => {
							const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name });

							if (typeof vehicle_column !== 'undefined') {
								const colObject = _.find(vehicle.vehicle_properties, { key: vehicle_column.name }) || '';

								return <span>
									<label className="space-top-1">{tab_property.name}</label>
									<AllInputs
										name={vehicle_column.name}
										label={vehicle_column.name}
										onChange={this.onChange}
										onBlur={this.onBlur}
										username={colObject.user_name}
										value={this.state[tab_property.name] || colObject.value || ''}
										type={vehicle_column.input_name}
										options={vehicle_column.options}
										checked={colObject.value || ''}
										updatetype={'vehicleproperty'}
										key={`vehicle_${vehicle.id}tab_property${colObject.id}`}
										parentid={vehicle.id}
										rowid={colObject.id || ''}
										uid={colObject.uid}
										updated_at={colObject.updated_at}
										icon={vehicle_column.icon}
										userStamp={this.props.userStamp}
										setdisabled={false}
										vehicle={vehicle}
									/>
								</span>;
							}
						})

					);
				}

				tab.tab_properties.forEach((tab_property, index) => {
						var dividerBar = ' | ';

						if (tab.tab_properties.length == (index + 1)) {
							var dividerBar = ' ';
						}

						const vehicle_column = _.find(vehicle.vehicle_properties, { key: tab_property.name }) || '';
						const tabStyle = defaultStyle;
						const iconstyle = [];
						if (typeof vehicle_column !== undefined) {
							if (vehicle_column.value === 'needed') {
								iconstyle.push(pendingStyle);
							} else if (vehicle_column.value === 'completed') {
								iconstyle.push(successStyle);
							}

							icons.push(<span key={`ti_${tab.id}_${vehicle.id}_${tab_property.name}`}>
								<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} iconstyle={iconstyle} icon={self.props.icon} value={vehicle_column.value} vehicle={vehicle} vehicle_id={vehicle.id} />
								{' '}
								{tab_property.name}
								{' '}
								{dividerBar}
                  </span>);
						}
					});
			} else if (tab.name === 'tab_estimate_stanley') {
				var status_estimates = _.find(vehicle.vehicle_properties, { key: 'status_estimates' }) || '';
				let val = '';

				if (status_estimates.value == 'pending') {
					var tabStyle = pendingStyle;
				} else if (status_estimates.value == 'success') {
					if (self.props.installation.database.includes('stanley')) {
						var uvi_approved_total = _.find(vehicle.vehicle_properties, { key: 'uvi_approved_total' }) || '';
						val = uvi_approved_total.value;
					}
					var tabStyle = successStyle;
				} else if (status_estimates.value == 'warning') {
					var tabStyle = warningStyle;
				} else if (status_estimates.value == 'getapproved') {
					var tabStyle = warningStyle;
				} else {
					var tabStyle = defaultStyle;
				}

				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
					<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
					{' '}
					{val}
               </span>);
			} else if (tab.name === 'tab_estimate' || tab.name === 'tab_new_work_items') {
				var status_estimates = _.find(vehicle.vehicle_properties, { key: 'status_estimates' }) || '';
				const val = '';

				if (status_estimates.value == 'pending') {
					var tabStyle = pendingStyle;
				} else if (status_estimates.value == 'available') {
					var tabStyle = availableStyle;
				} else if (status_estimates.value == 'success') {
					var tabStyle = successStyle;
				} else if (status_estimates.value == 'warning') {
					var tabStyle = warningStyle;
				} else if (status_estimates.value == 'getapproved') {
					var tabStyle = warningStyle;
				} else {
					var tabStyle = defaultStyle;
				}

				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
					<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
					{' '}
					{val}
               </span>);
			} else if (tab.name === 'tab_stanley') {
				var status_estimates = _.find(vehicle.vehicle_properties, { key: 'status_estimates' }) || '';
				var mechanical_completed = _.find(vehicle.vehicle_properties, { key: 'mechanical completed' }) || '';
				var uvi_total_approved = _.find(vehicle.vehicle_properties, { key: 'uvi_total_approved' }) || '';

				if (status_estimates.value == 'pending') {
					var tabStyle = pendingStyle;
				} else if (status_estimates.value == 'warning') {
					var tabStyle = warningStyle;
				} else if (status_estimates.value == 'getapproved') {
					var tabStyle = warningStyle;
				} else {
					var tabStyle = defaultStyle;
				}

				if (mechanical_completed.value == 'true') {
					var tabStyle = successStyle;
				}

				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
					<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
					{' '}
				</span>);
			} else if (tab.name === 'tab_mechanical') {
				const status_work_items = _.find(vehicle.vehicle_properties, { key: 'status_work_items' }) || '';

				if (status_work_items.value == 'pending') {
					var tabStyle = pendingStyle;
				} else if (status_work_items.value == 'success') {
					var tabStyle = successStyle;
				} else if (status_work_items.value == 'warning') {
					var tabStyle = warningStyle;
				} else {
					var tabStyle = defaultStyle;
				}

				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
					<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
					{' '}
				</span>);
			} else if (tab.name === 'tab_work_order_list') {
				const status_work_items = _.find(vehicle.vehicle_properties, { key: 'status_work_items' }) || '';

				if (status_work_items.value == 'pending') {
					var tabStyle = pendingStyle;
				} else if (status_work_items.value == 'success') {
					var tabStyle = successStyle;
				} else if (status_work_items.value == 'warning') {
					var tabStyle = warningStyle;
				} else {
					var tabStyle = defaultStyle;
				}

				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
					<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
					{' '}
				</span>);
			} else if (tab.name === 'tab_vendor') {
				var status_estimates = _.find(vehicle.vehicle_properties, { key: 'status_estimates' }) || '';

				if (status_estimates.value == 'pending') {
					var tabStyle = pendingStyle;
				} else if (status_estimates.value == 'success') {
					var tabStyle = successStyle;
				} else if (status_estimates.value == 'warning') {
					var tabStyle = warningStyle;
				} else {
					var tabStyle = defaultStyle;
				}

				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
					<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
					{' '}
				</span>);
			} else if (tab.name === 'tab_parts_order') {
				const status_parts = _.find(vehicle.vehicle_properties, { key: 'status_parts' }) || '';

				if (status_parts.value == 'pending') {
					var tabStyle = pendingStyle;
				} else if (status_parts.value == 'success') {
					var tabStyle = successStyle;
				} else if (status_parts.value == 'warning') {
					var tabStyle = warningStyle;
				} else {
					var tabStyle = defaultStyle;
				}

				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
					<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
					{' '}
				</span>);
			} else if (tab.name === 'tab_cost') {
				tab.tab_properties.map((tab_property, index) => {
					var dividerBar = ' | ';

					if (tab.tab_properties.length == (index + 1)) {
						var dividerBar = ' ';
					}

					const tabStyle = defaultStyle;
					const vehicle_column = _.find(vehicle.vehicle_properties, { key: tab_property.name }) || '';

					var cost = vehicle_column.value ? numberWithCommas(vehicle_column.value) : '';
					if ((this.props.current_dealership === 'miltonmartin') && (vehicle.stocktype === 'U')) {
						cost = vehicle_column.value ? numberWithCommas((Number(vehicle_column.value) + 2000)) : '';
					}

					if (typeof vehicle_column !== undefined) {
						icons.push(<span key={`ti_${tab.id}_${vehicle.id}_${tab_property.name}`}>
							<TabIcon iconstyle={tabStyle} vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} value={vehicle_column.value} vehicle={vehicle} vehicle_id={vehicle.id} />
							{' '}
							{cost}
							{' '}
							{dividerBar}
                 </span>);
					}
				});
			} else if (tab.name === 'tab_nysi_ready') {
					var tabStyle = defaultStyle;
					const vehicle_column = _.find(vehicle.vehicle_properties, { key: 'nysi ready' }) || '';

					var nysi = vehicle_column.value ? vehicle_column.value : '';

					if (nysi == 'true') {
						tabStyle = successStyle;
					} else {
						tabStyle = defaultStyle;
					}

					if (typeof vehicle_column !== undefined) {
						icons.push(<span key={`ti_${tab.id}_${vehicle.id}_nysi`}>
							{'NYSI Ready: '}
							<TabIcon iconstyle={tabStyle} vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} value={vehicle_column.value} vehicle={vehicle} vehicle_id={vehicle.id} />

                 </span>);
					}
			} else if (tab.name === 'tab_stocktype' && vehicle.stocktype) {
				icons.push(<span key={`st_${vehicle.id}`} title={vehicle.stocktype}>{vehicle.stocktype.substr(0, 1)}</span>);
			} else if (tab.name === 'tab_last_note') {
				const notes = vehicle.vehicle_notes;
				var last_note = '';
				notes.map((note) => {
					const userid = self.props.lookupUserById(note.uid);
					last_note = `${userid.user_name} - ${note.value.replace(/#/g, '')} | ${noteStamp(note.created_at)}`;
				});
				if (self.props.current_user.position == 'detail view' || self.props.current_user.position == 'reconditioning inspection' && vehicle.stocktype) {
					icons.push(<span key={`st_${vehicle.id}`} title={vehicle.stocktype}>{last_note}</span>);
				} else {
					icons.push(<span key={`st_${vehicle.id}`} title={vehicle.stocktype} />);
				}
			} else if (tab.name === 'tab_carfax') {
				if (vehicle.stocktype !== 'N' && vehicle.stocktype !== 'New' && vehicle.stocktype) {
					const problems = _.find(vehicle.vehicle_properties, { key: 'CarfaxProblems' }) || '';
					if (problems.value === 'Yes') {
						var tabStyle = warningStyle;
					}
					if (this.props.installation.database === 'classic') {
						icons.push(<span key={`st_${vehicle.id}`} title={vehicle.stocktype}><a href={`https://www.carfax.com/VehicleHistory/p/Report.cfx?partner=DON_0&vin=${vehicle.vin}`} target="_blank"><img src="https://cdn.ipacketrecon.com/images/carfox.png" /></a></span>);
					} else {
						icons.push(<span key={`st_${vehicle.id}`} title={vehicle.stocktype}><a href={`https://www.carfax.com/VehicleHistory/p/Report.cfx?partner=DEY_0&vin=${vehicle.vin}`} target="_blank"><img src="https://cdn.ipacketrecon.com/images/carfox.png" /></a></span>);
					}
				}
			} else if (tab.name === 'tab_vauto') {
				const description = _.find(vehicle.vehicle_properties, { key: 'AppraisalDescription' }) || '';
				const last_modified = _.find(vehicle.vehicle_properties, { key: 'VautoLastModified' }) || '';
				const appraised_value = _.find(vehicle.vehicle_properties, { key: 'AppraisedValue' }) || '';
				if (description.value && description.value.length > 0) {
					var tabStyle = successStyle;
				}
				if (last_modified.value && last_modified.value.length > 0) {
					var tabStyle = successStyle;
				}
				if (appraised_value.value && appraised_value.value.length > 0) {
					var tabStyle = successStyle;
				}
				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
					<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
					{' '}
				</span>);
			} else if (tab.name === 'tab_uci') {
				const inspection_status = _.find(vehicle.vehicle_properties, { key: 'inspection status' }) || [];
				const tech_status = _.find(vehicle.vehicle_properties, { key: 'tech status' }) || [];
				if (tech_status.value && tech_status.value === 'pending') {
					var tabStyle = pendingStyle;
				}
				if (inspection_status.value && inspection_status.value === 'completed') {
					var tabStyle = successStyle;
				}
				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>UCI</span>);
			} else if (tab.name === 'tab_body_work') {
				const inspection_status = _.find(vehicle.vehicle_properties, { key: 'bodywork status' }) || [];
				if (inspection_status.value && inspection_status.value === 'pending') {
					var tabStyle = pendingStyle;
				}
				if (inspection_status.value && inspection_status.value === 'completed') {
					var tabStyle = successStyle;
				}
				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
					<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
					{' '}
				</span>);
			} else if (tab.name === 'tab_needed_checkboxes') {
				if (this.props.installation.database === 'wp') {
					var newrows = ['pd', 'access', 'tint', 'sublets', 'aftermarket'];

					tab.tab_properties.map((tab_property, index) => {
						var dividerBar = ' | ';

						if (tab.tab_properties.length == (index + 1)) {
							var dividerBar = ' ';
						}

						const tabStyle = defaultStyle;
						const vehicle_column = _.find(vehicle.vehicle_properties, { key: tab_property.name }) || '';

						if (vehicle.stocktype === 'N') {
							if (newrows.includes(tab_property.name)) {
								if (typeof vehicle_column !== undefined) {
									icons.push(<span key={`ti_${tab.id}_${vehicle.id}_${tab_property.name}`}>
										<TabIcon iconstyle={tabStyle} vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} value={vehicle_column.value} vehicle={vehicle} vehicle_id={vehicle.id} />
										{' '}
										{tab_property.name}
										{' '}
										{dividerBar}
                    </span>);
								}
							}
						} else if (typeof vehicle_column !== undefined) {
							if (!newrows.includes(tab_property.name)) {
								icons.push(<span key={`ti_${tab.id}_${vehicle.id}_${tab_property.name}`}>
									<TabIcon iconstyle={tabStyle} vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} value={vehicle_column.value} vehicle={vehicle} vehicle_id={vehicle.id} />
									{' '}
									{tab_property.name}
									{' '}
									{dividerBar}
                   </span>);
							}
						}
					});
				} else {
					tab.tab_properties.map((tab_property, index) => {
						var dividerBar = ' | ';

						if (tab.tab_properties.length == (index + 1)) {
							var dividerBar = ' ';
						}

						const tabStyle = defaultStyle;
						const vehicle_column = _.find(vehicle.vehicle_properties, { key: tab_property.name }) || '';

						if (typeof vehicle_column !== undefined) {
							icons.push(<span key={`ti_${tab.id}_${vehicle.id}_${tab_property.name}`}>
								<TabIcon iconstyle={tabStyle} vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} value={vehicle_column.value} vehicle={vehicle} vehicle_id={vehicle.id} />
								{' '}
								{tab_property.name}
								{' '}
								{dividerBar}
                  </span>);
						}
					});
				}
			} else if (tab.name === 'tab_edit_vehicle') {
				if (vehicle.imported === true && vehicle.dropped !== true) {
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				} else if (vehicle.dropped === true) {
					var tabStyle = pendingStyle;
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				} else {
					var tabStyle = warningStyle;
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				}
			} else if (tab.name === 'tab_recall') {
				const open_recall = _.find(vehicle.vehicle_properties, { key: 'open_recall' }) || [];

				if (open_recall.value == 'true') {
					var tabStyle = warningStyle;
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				} else {
					var tabStyle = successStyle;
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				}
			} else if (tab.name === 'tab_uvi') {
				const inspection_status = _.find(vehicle.vehicle_properties, { key: 'uvi status' }) || [];
				const advisor_status = _.find(vehicle.vehicle_properties, { key: 'advisor status' }) || [];
				if (inspection_status.value && inspection_status.value === 'waiting') {
					var tabStyle = pendingStyle;
				}
				if (advisor_status.value && inspection_status.value === 'pending') {
					var tabStyle = pendingStyle;
				}
				if (inspection_status.value && inspection_status.value === 'declined') {
					var tabStyle = warningStyle;
				}
				if (inspection_status.value && inspection_status.value === 'completed') {
					var tabStyle = successStyle;
				}
				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>UVI</span>);
			} else if (tab.name === 'tab_automax_form') {
				const inspection_status = _.find(vehicle.vehicle_properties, { key: 'uvi status' }) || [];
				const advisor_status = _.find(vehicle.vehicle_properties, { key: 'advisor status' }) || [];
				if (inspection_status.value && inspection_status.value === 'waiting') {
					var tabStyle = pendingStyle;
				}
				if (advisor_status.value && inspection_status.value === 'pending') {
					var tabStyle = pendingStyle;
				}
				if (inspection_status.value && inspection_status.value === 'declined') {
					var tabStyle = warningStyle;
				}
				if (inspection_status.value && inspection_status.value === 'completed') {
					var tabStyle = successStyle;
				}
				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
			} else if (tab.name === 'tab_history') {
				if (vehicle.completed_departments) {
					const days = 0;
					let department_time = 0;
					const department_id = vehicle.department_id;

					const first_time_tab = this.dealerSettingValue('first_time_tab');
					const second_time_tab = this.dealerSettingValue('second_time_tab');
					const third_time_tab = this.dealerSettingValue('third_time_tab');

					let first_time = '';
					let second_time = '';
					let third_time = '';

					first_time = first_time_tab === 'none' ? '' : this.returnTimeDisplay(first_time_tab);
					second_time = second_time_tab === 'none' ? '' : this.returnTimeDisplay(second_time_tab);
					third_time = third_time_tab === 'none' ? '' : this.returnTimeDisplay(third_time_tab);

					department_time = getSecondsFromTimeRange(vehicle.department_time, end) / 3600;
					if (Number(this.props.department.goal) > (department_time)) {
						var tabStyle = successStyle;
					} else {
						var tabStyle = warningStyle;
					}

					icons.push(<span title={`${first_time_tab} / ${second_time_tab} / ${third_time_tab}`} key={`ti_${tab.id}_${vehicle.id}`}>
						{first_time}
						{' '}
|
						{' '}
						{second_time}
						{' '}
|
						{' '}
						{third_time}
						{' '}
					</span>);
				} else {
					var tabStyle = successStyle;
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>0</span>);
				}
			} else if (tab.name === 'tab_file_attachment') {
				if (vehicle.attachments.length > 0) {
					var tabStyle = successStyle;
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						{vehicle.attachments.length}
						{' '}
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				} else {
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				}
			} else if (tab.name === 'tab_recon_tracker') {
				if (vehicle.attachments.length > 0) {
					var tabStyle = successStyle;
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						{vehicle.attachments.length}
						{' '}
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				} else {
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				}
			} else if (tab.name === 'tab_service_form') {
				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}><small>Service</small></span>);
			} else if (tab.name === 'tab_detail_slip') {
				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}><small>Detail Slip</small></span>);
			} else if (tab.name === 'tab_loaner_form') {
				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}><small>Loaner</small></span>);
			} else if (tab.name === 'tab_expenses') {
				const expense_total = _.find(vehicle.vehicle_properties, { key: 'expense_total' }) || [];
				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}><small>Ex: ${expense_total.value}</small></span>);
			} else if (tab.name === 'tab_multiple_vins') {
				var vincount = 0;
				const colObject = this.vehicleProperty('duplicate_vins');
				var vincount = colObject.split(',').length;
				if (vincount > 1) {
					var tabStyle = successStyle;
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						{vincount}
						{' '}
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				} else {
					var tabStyle = defaultStyle;
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				}
			} else if (tab.name === 'tab_pictures') {
				var photocount = 0;
				const colObject = _.find(vehicle.vehicle_properties, { key: 'count_photos' }) || [];
				const countcheck = Number(tab.icon_success_condition_equal);
				var photocount = colObject.value || 0;
				if (photocount < countcheck) {
					var tabStyle = warningStyle;
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						{photocount}
						{' '}
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				} else {
					var tabStyle = successStyle;
					icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
						{photocount}
						{' '}
						<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
						{' '}
					</span>);
				}
			} else {
				icons.push(<span key={`ti_${tab.id}_${vehicle.id}`}>
					<TabIcon vehicle={vehicle} vehicle_id={vehicle.id} tab={tab} icon={self.props.icon} />
					{' '}
				</span>);
			}
		}
		//TODO rewrite confusing tab style definition system. Default colors should be defined in styles not in js -Khris
		let tabStylesRemovedDefaultColors = {...tabStyle}
		if(tabStylesRemovedDefaultColors.background === '#343a40' || tabStylesRemovedDefaultColors.backgroundColor ==='#343a40'){
			tabStylesRemovedDefaultColors.opacity = '0'
		}
		return (
			<div className="flex-row d-flex align-items-stretch tabRow" data-tab-name={tab.name}>
				<span className={'ReactVehicleHeadTabSmall tab-link-tab-buttons'} onClick={this.onTabClick}>
						{hideColorIndicator?null:
							<div className="tab-link-color-indicator-container" ><div className="tab-link-color-indicator" style={tabStylesRemovedDefaultColors}/></div>
						}
						{icons}
				</span>
			</div>

		);
	}
}

const mappedActions = {
	setDepartmentHistories,
	setPhotos,
	setVehicleTab,
	addVehicleProperty,
	getPhotosByVid,
	setCustomModalTab,
	returnTabAge,
	returnHoursFromTimestamp,
	returnTabAge_24_5,
	returnTabAge_24_6,
	returnTabAge_24_7,
	lookupUserById,
};

function mapStateToProps(state, ownProps) {
	return {
		vehicle_columns: state.settings_departments.vehicle_columns,
		department: state.settings_departments.department,
		dealer_settings: state.settings_site.dealer_settings,
		input_types: state.settings_departments.input_types,
		current_user: state.current_user,
		current_dealership: state.settings_dealerships.current_dealership,
		related_installations: state.settings_dealerships.related_installations,
		installation: state.settings_dealerships.installation,
		work_flows: state.settings_departments.work_flows,
		isCurrentlySelectedTab: (ownProps.name === state.settings_nav.menus.vehicle_tab) && (state.settings_nav.edit_vehicle === ownProps.vehicle.id),
	};
}
export default connect(mapStateToProps, mappedActions)(TabLink);
