import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import VehicleCount from './VehicleCount';
import SidebarSubmenu from './SidebarSubmenu';
import { setVehicleFilter, setCurrentDepartment, setCancelOldRequests } from '../../actions/apiSetInfo';
import { getCurrentDepartment } from '../../actions/apiGetInfo';
import { change_page, change_page_mobile } from '../../actions/toggleMenu';
import { lookupDepartmentById } from '../../actions/lookups';
import './SidebarListElement.scss'

class SidebarListElement extends Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);

		this.state = {};
	}

	dealerSettingValue(name) {
		// TODO: this must be moved to some shared utils
		// as it used through whole project
			const self = this;
			const settings = self.props.dealer_settings;
			let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
			if (value.length === 0) {
				value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
			}
			if (value.length > 0) {
				if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
					return value[0].value;
				}
			}
			return '';
		}

	onClick(e) {
		const vehicle_filter = { type: 'department', value: e.target.dataset.id };
		const department = this.props.department;

		this.props.getCurrentDepartment(this.props.department.id);
		this.props.setCurrentDepartment({ department });
		this.props.setVehicleFilter(vehicle_filter, this.props.department.id);

		window.scrollTo(0, 0);
		if (isMobile) {
			this.props.change_page_mobile('content');
		} else {
			this.props.change_page('content');
		}
	}

	onClickMobile(e) {
		const vehicle_filter = { type: 'department', value: e.target.dataset.id };
		this.props.getCurrentDepartment(this.props.department.id);
		this.props.setVehicleFilter(vehicle_filter, this.props.department.id);

		window.scrollTo(0, 0);
		this.props.change_page_mobile('content');
	}

	render() {
		const department = this.props.department;

		const { current_department_id } = this.props;

		const isData = department.department_type === 'data step';
		const grey = isData ? 'grey-link' : '';

		const dataSyle = {
			background: department.background_color || this.dealerSettingValue('color_default_data') || 'rgb(38, 50, 56)' || undefined,
			color: department.text_color || this.dealerSettingValue('color_default_data_text') || '#FFF' || undefined,
		};
		const sidebarStyle = {
			background: department.background_color || this.dealerSettingValue('color_sidebar') || undefined,
			color: department.text_color || this.dealerSettingValue('color_sidebar_text') || undefined,
		};
		const style = isData ? dataSyle : sidebarStyle;

		const badgeStyle = {
			background: department.badge_background_color,
			backgroundColor: department.badge_background_color,
			color: department.badge_text_color,
		};

		return (
			<li className={`sidebar-nav-item ${current_department_id == department.id ? 'nav-item active' : 'nav-item'}`}>
				<a style={style} className={'nav-link'} data-id={department.id} onClick={this.onClick}>
					<div className="sidebar-nav-item-info">
						<div className="sidebar-nav-item-info-name">
							{department.subgroup > 0 ? <span>
								<i className={'fal fa-ellipsis-v'}> </i>
								{' '}
							</span> : ''}
							{department.display_name || department.name}
						</div>
						<div className="sidebar-nav-item-info-counts">
							<span className="badge badge-primary vehicle-badge-counts" style={badgeStyle}>{this.props.count}</span>
							<span className={`badge badge-primary vehicle-badge-counts${!this.props.overdue_1?' vehicle-badge-counts-hidden':''}`} style={{...badgeStyle,backgroundColor:'#ffaa01'}} >{this.props.overdue_1?this.props.overdue_1:'NA'}</span>
							<span className={`badge badge-primary vehicle-badge-counts${!this.props.overdue_2?' vehicle-badge-counts-hidden':''}`} style={{...badgeStyle,backgroundColor:'#ff4012'}} >{this.props.overdue_2?this.props.overdue_2:'NA'}</span>
						</div>
					</div>
				</a>

				{this.props.site_map !== true ? <SidebarSubmenu parent_department={department.id} /> : ''}
			</li>
		);
	}
}

const mappedActions = {
	change_page,
	setVehicleFilter,
	getCurrentDepartment,
	setCurrentDepartment,
	change_page_mobile,
	setCancelOldRequests,
};

function mapStateToProps(state) {
	return {
		current_department: state.settings_departments.department,
		site_map: state.settings_nav.menus.site_map,
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps, mappedActions)(SidebarListElement);
