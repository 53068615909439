import React, { Component } from 'react';
import { connect } from 'react-redux';
import SupportChatWidget from '../../containers/Chat/SupportChatWidget';
import Chat from '../../containers/Chat';

class SidebarRight extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	render() {
		var buttons = [];

		if (this.dealerSettingValue('live_chat') === 'true') {
			buttons.push(<Chat />);
		}

		if (this.dealerSettingValue('support-chat') === 'true') {
			buttons.push(<SupportChatWidget />);
		}

		return (
			<div className={(this.props.sidebar_status_right === false) ? 'no-display' : 'col-md-4 col-lg-3 sidebar-offcanvas-right pad-left-0 pad-right-0 sidebar-right'} key="sidebar-right" id="sidebar-right" role="navigation">
				<h4 className="modal-title bg-light"><img key="toplogo" src="https://cdn.ipacketrecon.com/recon_images/recon-chat.png" key="support-chat" name="support-chat" style={{ marginTop: 5 }} height={30} /></h4>
				{buttons}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		page: state.settings_nav.page,
		sidebar_status_right: state.settings_nav.menus.sidebar_status_right,
		departments: state.settings_departments.departments,
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps)(SidebarRight);
