import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';

class WorkingHours extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	render() {
		const self = this;
		const { props } = this;

		return (
			<div className="col-md-12 working-hours">

				<div className="card space-top-0">
					<h3 className="text-center pad-top-1">Working Hours Settings</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text pad-top-2">

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'select-timezone'} name="dealership_time_zone" label="Dealership Time Zone" value={this.dealerSettingValue('dealership_time_zone')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'select-time-tabs'} name="dealership_report_hours" label="Dealership Report Hours" value={this.dealerSettingValue('dealership_report_hours')} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card space-top-0">
					<h3 className="text-center pad-top-1">Select Vehicle Tab History Definitions</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text pad-top-2">

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'select-time-tabs'} name="first_time_tab" label="First Displayed Time" value={this.dealerSettingValue('first_time_tab')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'select-time-tabs'} name="second_time_tab" label="Second Displayed Time" value={this.dealerSettingValue('second_time_tab')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'select-time-tabs'} name="third_time_tab" label="Third Displayed Time" value={this.dealerSettingValue('third_time_tab')} />
								</div>

							</div>
						</div>
					</div>
				</div>

				<div className="card space-top-0">
					<h3 className="text-center pad-top-1">Dealership Working Hours -- (not activated - ask RA support)</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text pad-top-2">

							<hr />
							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_sunday_start" label="Sunday Start" value={this.dealerSettingValue('dealership_hours_sunday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_sunday_end" label="Sunday End" value={this.dealerSettingValue('dealership_hours_sunday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_monday_start" label="Monday Start" value={this.dealerSettingValue('dealership_hours_monday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_monday_end" label="Monday End" value={this.dealerSettingValue('dealership_hours_monday_end')} />
								</div>
							</div>

							<hr />
							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_tuesday_start" label="Tuesday Start" value={this.dealerSettingValue('dealership_hours_tuesday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_tuesday_end" label="Tuesday End" value={this.dealerSettingValue('dealership_hours_tuesday_end')} />
								</div>
							</div>
							<hr />
							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_wednesday_start" label="Wednesday Start" value={this.dealerSettingValue('dealership_hours_wednesday_start')} />
								</div>
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_wednesday_end" label="Wednesday End" value={this.dealerSettingValue('dealership_hours_wednesday_end')} />
								</div>
							</div>

							<hr />
							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_thursday_start" label="Thursday Start" value={this.dealerSettingValue('dealership_hours_thursday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_thursday_end" label="Thursday End" value={this.dealerSettingValue('dealership_hours_thursday_end')} />
								</div>
							</div>

							<hr />
							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_friday_start" label="Friday Start" value={this.dealerSettingValue('dealership_hours_friday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_friday_end" label="Friday End" value={this.dealerSettingValue('dealership_hours_friday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_saturday_start" label="Saturday Start" value={this.dealerSettingValue('dealership_hours_saturday_start')} />
								</div>
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="dealership_hours_saturday_end" label="Saturday End" value={this.dealerSettingValue('dealership_hours_saturday_end')} />
								</div>
							</div>

						</div>
					</div>
					<h3 className="text-center pad-top-1">Service Working Hours</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text">

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_sunday_start" label="Sunday Start" value={this.dealerSettingValue('service_hours_sunday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_sunday_end" label="Sunday End" value={this.dealerSettingValue('service_hours_sunday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_monday_start" label="Monday Start" value={this.dealerSettingValue('service_hours_monday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_monday_end" label="Monday End" value={this.dealerSettingValue('service_hours_monday_end')} />
								</div>
							</div>

							<hr />
							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_tuesday_start" label="Tuesday Start" value={this.dealerSettingValue('service_hours_tuesday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_tuesday_end" label="Tuesday End" value={this.dealerSettingValue('service_hours_tuesday_end')} />
								</div>
							</div>
							<hr />
							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_wednesday_start" label="Wednesday Start" value={this.dealerSettingValue('service_hours_wednesday_start')} />
								</div>
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_wednesday_end" label="Wednesday End" value={this.dealerSettingValue('service_hours_wednesday_end')} />
								</div>
							</div>

							<hr />
							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_thursday_start" label="Thursday Start" value={this.dealerSettingValue('service_hours_thursday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_thursday_end" label="Thursday End" value={this.dealerSettingValue('service_hours_thursday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_friday_start" label="Friday Start" value={this.dealerSettingValue('service_hours_friday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_friday_end" label="Friday End" value={this.dealerSettingValue('service_hours_friday_end')} />
								</div>
							</div>
							<hr />
							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_saturday_start" label="Saturday Start" value={this.dealerSettingValue('service_hours_saturday_start')} />
								</div>
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="service_hours_saturday_end" label="Saturday End" value={this.dealerSettingValue('service_hours_saturday_end')} />
								</div>
							</div>

						</div>
					</div>

					<h3 className="text-center pad-top-1">Sales Working Hours</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text">

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_sunday_start" label="Sunday Start" value={this.dealerSettingValue('sales_hours_sunday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_sunday_end" label="Sunday End" value={this.dealerSettingValue('sales_hours_sunday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_monday_start" label="Monday Start" value={this.dealerSettingValue('sales_hours_monday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_monday_end" label="Monday End" value={this.dealerSettingValue('sales_hours_monday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_tuesday_start" label="Tuesday Start" value={this.dealerSettingValue('sales_hours_tuesday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_tuesday_end" label="Tuesday End" value={this.dealerSettingValue('sales_hours_tuesday_end')} />
								</div>
							</div>
							<hr />
							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_wednesday_start" label="Wednesday Start" value={this.dealerSettingValue('sales_hours_wednesday_start')} />
								</div>
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_wednesday_end" label="Wednesday End" value={this.dealerSettingValue('sales_hours_wednesday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_thursday_start" label="Thursday Start" value={this.dealerSettingValue('sales_hours_thursday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_thursday_end" label="Thursday End" value={this.dealerSettingValue('sales_hours_thursday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_friday_start" label="Friday Start" value={this.dealerSettingValue('sales_hours_friday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_friday_end" label="Friday End" value={this.dealerSettingValue('sales_hours_friday_end')} />
								</div>
							</div>
							<hr />
							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_saturday_start" label="Saturday Start" value={this.dealerSettingValue('sales_hours_saturday_start')} />
								</div>
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="sales_hours_saturday_end" label="Saturday End" value={this.dealerSettingValue('sales_hours_saturday_end')} />
								</div>
							</div>

						</div>
					</div>

					<h3 className="text-center pad-top-1">Office Working Hours</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text">

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_sunday_start" label="Sunday Start" value={this.dealerSettingValue('office_hours_sunday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_sunday_end" label="Sunday End" value={this.dealerSettingValue('office_hours_sunday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_monday_start" label="Monday Start" value={this.dealerSettingValue('office_hours_monday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_monday_end" label="Monday End" value={this.dealerSettingValue('office_hours_monday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_tuesday_start" label="Tuesday Start" value={this.dealerSettingValue('office_hours_tuesday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_tuesday_end" label="Tuesday End" value={this.dealerSettingValue('office_hours_tuesday_end')} />
								</div>
							</div>
							<hr />
							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_wednesday_start" label="Wednesday Start" value={this.dealerSettingValue('office_hours_wednesday_start')} />
								</div>
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_wednesday_end" label="Wednesday End" value={this.dealerSettingValue('office_hours_wednesday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_thursday_start" label="Thursday Start" value={this.dealerSettingValue('office_hours_thursday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_thursday_end" label="Thursday End" value={this.dealerSettingValue('office_hours_thursday_end')} />
								</div>
							</div>
							<hr />

							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_friday_start" label="Friday Start" value={this.dealerSettingValue('office_hours_friday_start')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_friday_end" label="Friday End" value={this.dealerSettingValue('office_hours_friday_end')} />
								</div>
							</div>
							<hr />
							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_saturday_start" label="Saturday Start" value={this.dealerSettingValue('office_hours_saturday_start')} />
								</div>
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'timepicker'} name="office_hours_saturday_end" label="Saturday End" value={this.dealerSettingValue('office_hours_saturday_end')} />
								</div>
							</div>

						</div>
					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return { 
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	 };
}
export default connect(mapStateToProps)(WorkingHours);
