import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
	updateDepartmentSetting,
	updateVehicleColumn,
} from '../actions/apiDepartmentUpdates';
import {
	updateVehicleDepartment,
} from '../actions/AllUpdates';
import {
	sendEstimate, sendEstimateUpdate, sendEstimateRaw,
} from '../actions/apiEstimates';
import {
	setSingleUser,
	editWorkFlows,
	setCurrentReport,
	setReportStartDate,
	setReportEndDate,
	setReportTimeRange,
	setEditDepartments,
	setVehicleTab,
	setMonthlyReportDepartments,
	setMonthlyReportWorkFlows,
} from '../actions/apiSetInfo';
import {
	addDealerSetting,
} from '../actions/apiDealerUpdates';
import {
	updateFilter,
} from '../actions/apiFilters';
import {
	editUserProfile,
} from '../actions/users';
import {
	change_page,
} from '../actions/toggleMenu';
import {
	activeEditDepartment, activeVehicle,
} from '../actions/lookups';

class Dropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listOpen: false,
			headerTitle: this.props.title,
			list: this.props.list,
		};
		this.toggleList = this.toggleList.bind(this);
		this.close = this.close.bind(this);
		this.resetThenSet = this.resetThenSet.bind(this);
	}

	componentDidUpdate() {
		const { listOpen } = this.state;
		setTimeout(() => {
			if (listOpen) {
				window.addEventListener('click', this.close);
			} else {
				window.removeEventListener('click', this.close);
			}
		}, 0);
	}

	componentDidMount() {
		if (this.props.value) {
			if (this.props.update_type == 'subdomain') {
				var self = this;
				var index = _.findIndex(self.state.list, { subdomain: this.props.value });

				var { list } = self.state;

				list.forEach((item, ind) => {
					if (index !== ind) {
						return item;
					}
					self.setState({
						headerTitle: item.label,
					});
				});
			} else if (this.props.update_type == 'report_time_range') {
				var self = this;
				var index = _.findIndex(self.state.list, { name: this.props.value });

				var { list } = self.state;

				list.forEach((item, ind) => {
					if (index !== ind) {
						return item;
					}
					self.setState({
						headerTitle: item.label,
					});
				});
			} else if (this.props.update_type.includes('dealership_setting')) {
				var self = this;
				var index = _.findIndex(self.state.list, { name: this.props.value });

				var { list } = self.state;

				list.forEach((item, ind) => {
					if (index !== ind) {
						return item;
					}
					self.setState({
						headerTitle: item.label,
					});
				});
			} else if (isNaN(this.props.value)) {
				var self = this;
				var index = _.findIndex(self.state.list, { label: this.props.value });

				var { list } = self.state;

				list.forEach((item, ind) => {
					if (index !== ind) {
						return item;
					}
					self.setState({
						headerTitle: item.label,
					});
				});
			} else {
				var self = this;
				var index = _.findIndex(self.state.list, { id: parseInt(this.props.value) });

				var { list } = self.state;

				list.forEach((item, ind) => {
					if (index !== ind) {
						return item;
					}
					self.setState({
						headerTitle: item.label,
					});
				});
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.close);
	}

	close(timeOut) {
		this.setState({
			listOpen: false,
		});
	}

	selectItem(title, id) {
		this.setState({
			headerTitle: title,
			listOpen: false,
		}, this.resetThenSet(id));
	}

	toggleList() {
		this.setState(prevState => ({
			listOpen: !prevState.listOpen,
		}));
	}

	resetThenSet(id) {
		const self = this;
		const index = _.findIndex(self.state.list, { id: parseInt(id) });
		let headerTitle = this.props.title;
		const { list } = self.state;
		list.forEach(item => item.selected = false);
		const newObj = list.map((item, ind) => {
			if (index !== ind) {
				return item;
			}
			item.selected = true;
			headerTitle = item.label;

			if (this.props.update_type == 'subdomain_department') {
				if (item.selected === true) {
					var errorType = 'editVehicleColumnError';

					var data = {
						id: self.props.department_id,
						subdomain_department: item.id,
					};

					self.props.updateDepartmentSetting(self.props.department_id, data, errorType);
				}
			} else if (this.props.update_type == 'vehicle_column') {
				if (item.selected === true) {
					var errorType = 'editVehicleColumnError';

					var data = {
						id: self.props.vehicle_column_id,
						[this.props.name]: item.value,
					};

					this.props.updateVehicleColumn(data.id, data, errorType);
				}
			} else if (this.props.update_type == 'all_filter') {
				if (item.selected === true) {
					var errorType = 'editAllFilter';

					var data = {
						id: self.props.all_filter_id,
						[this.props.name]: item.value,
					};

					this.props.updateFilter(data.id, data, errorType);
				}
			} else if (this.props.update_type == 'select_department') {
				if (item.selected === true) {
					self.props.updateVehicleDepartment(self.props.vehicle_id, 'department_id', item.id, self.props.vehicle);
				}
			} else if (this.props.update_type == 'vehicle_body') {
				if (item.selected === true) {
					self.props.onChange(item.label);
				}
			} else if (this.props.update_type == 'uvi_form_vehicle') {
				if (item.selected === true) {
					self.props.onChange(item.label, item.part_number, item.qty, item.parts, item.labor_hours, item.labor, item.total, item.work_id);
				}
			} else if (this.props.update_type == 'vehicleproperty') {
				if (item.selected === true) {
					self.props.onChange(item.label, self.props.name);
				}
			} else if (this.props.update_type == 'subgroup') {
				if (item.selected === true) {
					var errorType = 'subgroup';

					var data = {
						id: self.props.department_id,
						subgroup: item.id,
					};

					self.props.updateDepartmentSetting(self.props.department_id, data, errorType);
				}
			} else if (this.props.update_type == 'single_user') {
				if (item.selected === true) {
					var errorType = 'single_user';

					self.props.setSingleUser(item.id);
				}
			} else if (this.props.update_type == 'edit_work_flows') {
				if (this.props.onChange) {
					this.props.onChange(item);
				}
				if (item.selected === true) {
					var errorType = 'edit_work_flows';

					self.props.editWorkFlows(item.id);
				}
			} else if (this.props.update_type == 'department_type') {
				if (item.selected === true) {
					var errorType = 'department_type';

					var data = {
						id: self.props.department_id,
						department_type: item.label,
					};

					self.props.updateDepartmentSetting(self.props.department_id, data, errorType);
				}
			} else if (this.props.update_type == 'dealership_setting') {
				if (item.selected === true) {
					var errorType = 'dealership_setting';

					var data = {
						key: this.props.name,
						value: item.label,
						dealership: self.props.installation.subdomain,
					};

					self.props.addDealerSetting(data, errorType);
				}
			} else if (this.props.update_type == 'pay_type') {
				if (item.selected === true) {
					const id = item.estimate_id;
					var errorType = 'sendEstimate';

					var data = {
						id,
						type_item_2: item.name,
					};

					self.props.sendEstimate(id, data, errorType);
				}
			} else if (this.props.update_type == 'new_work_item') {
				if (item.selected === true) {
					const id = this.props.estimate_id;
					var errorType = 'sendEstimate';

					var data = {
						id,
						item_1: item.item_1,
						item_2: item.item_2,
						item_3: item.item_3,
						type_item_5: item.type_item_5,
						classification: item.classification,
						estimate: item.estimate,
						vehicle_id: this.props.vehicle_id,
						description: item.value,
					};

					Promise.all([
						self.props.sendEstimate(id, data, errorType),
					]);
				}
			} else if (this.props.update_type == 'dealership_setting_department') {
				if (item.selected === true) {
					var errorType = 'dealership_setting';

					var data = {
						key: this.props.name,
						value: item.id,
						dealership: self.props.installation.subdomain,
					};

					self.props.addDealerSetting(data, errorType);
				}
			} else if (this.props.update_type == 'dealership_setting_report_hours') {
				if (item.selected === true) {
					var errorType = 'dealership_setting';

					var data = {
						key: this.props.name,
						value: item.name,
						dealership: self.props.installation.subdomain,
					};

					self.props.addDealerSetting(data, errorType);
				}
			} else if (this.props.update_type == 'dealership_setting_time_tabs') {
				if (item.selected === true) {
					var errorType = 'dealership_setting';

					var data = {
						key: this.props.name,
						value: item.name,
						dealership: self.props.installation.subdomain,
					};

					self.props.addDealerSetting(data, errorType);
				}
			} else if (this.props.update_type == 'dealership_setting_workflows') {
				if (item.selected === true) {
					var errorType = 'dealership_setting';

					var data = {
						key: this.props.name,
						value: item.label,
						dealership: self.props.installation.subdomain,
					};

					self.props.addDealerSetting(data, errorType);
				}
			} else if (this.props.update_type == 'subdomain') {
				if (item.selected === true) {
					var errorType = 'subdomain';

					var data = {
						id: self.props.department_id,
						subdomain: item.subdomain,
					};

					self.props.updateDepartmentSetting(self.props.department_id, data, errorType);
				}
			} else if (this.props.update_type == 'vehicle_subdomain') {
				if (item.selected === true) {
					var errorType = 'subdomain';

					var data = {
						id: self.props.vehicle_id,
						subdomain: item.name,
					};

					self.props.updateVehicleData(self.props.department_id, data);
				}
			} else if (this.props.update_type == 'vehicle_dealership') {
				if (item.selected === true) {
					var errorType = 'dealership';

					var data = {
						id: self.props.vehicle_id,
						dealership: item.name,
					};

					self.props.updateVehicleData(self.props.department_id, data);
				}
			} else if (this.props.update_type == 'set_current_report') {
				if (item.selected === true) {
					self.props.setCurrentReport(item.label);
				}
			} else if (this.props.update_type == 'set_edit_department') {
				if (item.selected === true) {
					self.props.activeEditDepartment(item.id);
					self.props.setEditDepartments(item.id);
				}
			} else if (this.props.update_type == 'set_monthly_report_departments') {
				if (item.selected === true) {
					self.props.setMonthlyReportDepartments(item.id);
				}
			} else if (this.props.update_type == 'set_monthly_report_work_flows') {
				if (item.selected === true) {
					self.props.setMonthlyReportWorkFlows(item.id);
				}
			} else if (this.props.update_type == 'report_time_range') {
				if (item.selected === true) {
					self.props.setReportTimeRange(item.name);

					var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
					if (months.includes(item.label)) {
						var date = new Date();
						var datefirst = new Date();
						var datelast = new Date();
						var lastdigit = new Date();

						if (item.name === 'last') {
							datefirst.setMonth(date.getMonth() - 1);
							datefirst.setDate(1);
							lastdigit = new Date(date.getFullYear(), date.getMonth(), 0);
						} else if (item.name === 'second') {
							datefirst.setMonth(date.getMonth() - 2);
							datefirst.setDate(1);
							lastdigit = new Date(date.getFullYear(), date.getMonth() - 1, 0);
						} else if (item.name === 'third') {
							datefirst.setMonth(date.getMonth() - 1);
							datefirst.setDate(1);
							lastdigit = new Date(date.getFullYear(), date.getMonth() - 2, 0);
						}

						var year = datefirst.getFullYear();
						var month = datefirst.getMonth() + 1;
						var day = datefirst.getDate();
						var start = `${datefirst.getFullYear()}-${(`0${datefirst.getMonth() + 1}`).slice(-2)}-${(`0${datefirst.getDate()}`).slice(-2)}`;
						var end = `${datefirst.getFullYear()}-${(`0${datefirst.getMonth() + 1}`).slice(-2)}-${lastdigit.getDate()}`;

						self.props.setReportStartDate(start);
						self.props.setReportEndDate(end);
					} else if (item.label.includes('days')) {
						var date = new Date();
						var startdate = new Date();
						startdate.setDate(startdate.getDate() - Number(item.name));
						var start = `${startdate.getFullYear()}-${(`0${startdate.getMonth() + 1}`).slice(-2)}-${(`0${startdate.getDate()}`).slice(-2)}`;
						var end = `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;

						self.props.setReportStartDate(start);
						self.props.setReportEndDate(end);
					} else if (item.label === 'Month to Date') {
						var date = new Date();
						var startdate = new Date();
						startdate.setDate(1);
						var start = `${startdate.getFullYear()}-${(`0${startdate.getMonth() + 1}`).slice(-2)}-${(`0${startdate.getDate()}`).slice(-2)}`;
						var end = `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;

						self.props.setReportStartDate(start);
						self.props.setReportEndDate(end);
					}
				}
			} else if (this.props.update_type == 'position') {
				if (item.selected === true) {
					const errorType = 'editUserProfile';

					const data = {
						position: item.label,
					};

					this.props.editUserProfile(this.props.user_id, data, errorType);
				}
			} else if (this.props.update_type == 'notification_preference') {
				if (item.selected === true) {
					const errorType = 'editUserProfile';

					const data = {
						notification_preference: item.label,
					};

					this.props.editUserProfile(this.props.user_id, data, errorType);
				}
			}

			return item;
		});

		this.setState({
			list: newObj,
			headerTitle,
		});
	}

	render() {
		const { list } = this.state;
		const { listOpen, headerTitle } = this.state;
		const { disabled } = this.props;

		return (
			<div className="dd-wrapper" key={`select_me${this.props.vehicle_id}`} tabIndex={this.props.tabindex}>
				<div className="dd-header" onClick={this.toggleList}>
					<div className="dd-header-title">{headerTitle}</div>
					<div className="text-muted">
						{listOpen
							? <i className="fal fa-2x fa-angle-up text-muted" />
							: <i className="fal fa-2x fa-angle-down text-muted" />}
					</div>
				</div>
				{!disabled && listOpen && <ul className={list.length > 10 ? 'dd-list scroll' : 'dd-list'} onClick={e => e.stopPropagation()}>
					{list.map(item => (
						<li className={item.selected ? 'dd-list-item selected' : 'dd-list-item'} key={item.id} onClick={() => this.selectItem(item.label, item.id)}>
							{item.label}
							{' '}
							{item.selected && <i className="fas fa-check float-right" />}
						</li>
					))}
				</ul>}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	};
}

const mappedActions = {
	updateDepartmentSetting,
	updateVehicleDepartment,
	updateVehicleColumn,
	setCurrentReport,
	setSingleUser,
	editWorkFlows,
	editUserProfile,
	addDealerSetting,
	sendEstimate,
	sendEstimateUpdate,
	setReportStartDate,
	setReportEndDate,
	setReportTimeRange,
	updateFilter,
	change_page,
	setEditDepartments,
	activeEditDepartment,
	sendEstimateRaw,
	setVehicleTab,
	activeVehicle,
	setMonthlyReportDepartments,
	setMonthlyReportWorkFlows,
};

export default connect(mapStateToProps, mappedActions)(Dropdown);
