import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';
import EditInstallation from '../../inputs/EditInstallation';
import DropBoxDealer from '../../inputs/FileUploadButton/DropBoxDealer';

class ColorSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value1: this.props.override_value_1, current_default_profile: this.props.current_default_profile, errors: { addDepartmentPropertyError: false }, option_name: '',
		};
	}

	dealerSettingValue(name) {
		const self = this;
		const settings = self.props.dealer_settings;
		let value = _.sortBy(_.filter(settings, o => o.key === name && o.dealership === self.props.installation.subdomain), 'id');
		if (value.length === 0) {
			value = _.sortBy(_.filter(settings, o => o.key === name && (o.dealership === '' || o.dealership === null || o.dealership === undefined)), 'id');
		}
		if (value.length > 0) {
			if (typeof value[0] !== 'undefined' && typeof value[0].value !== 'undefined') {
				return value[0].value;
			}
		}
		return '';
	}

	render() {
		const { installation } = this.props;
		const vehicle_tab_override = this.dealerSettingValue('vehicle_tab_override') || 'false';
		const html = [];
		if (vehicle_tab_override === 'true') {
			let i = 1;
			while (i < 16) {
				const override_value = this.dealerSettingValue(`override_value_${i}`) || '';
				const background_color_override = this.dealerSettingValue(`background_color_override_${override_value}`);
				const text_color_override = this.dealerSettingValue(`text_color_override_${override_value}`);

				html.push(<div className="row">
					<div className="col-md-4">
						<EditDealershipSettings {...this.props} type={'text'} name={`override_value_${i}`} label={`override_value_${i}`} value={override_value} />
					</div>
					<div className="col-md-4">
						<EditDealershipSettings {...this.props} type={'colorpicker'} name={`background_color_override_${override_value}`} label={`background_color_override_${override_value}`} value={background_color_override} />
					</div>
					<div className="col-md-4">
						<EditDealershipSettings {...this.props} type={'colorpicker'} name={`text_color_override_${override_value}`} label={`text_color_override_${override_value}`} value={text_color_override} />
					</div>
				</div>);

				i += 1;
			}
		}

		return (
			<div className="col-md-12">

				<div className="card space-top-0 v80">

					<h3 className="text-center pad-top-1">Color Default INFO</h3>

					<div className="card bg-light space-top-1 mx-auto w-75 p-3">

						<div className="row">
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'colorpicker'} name="color_header" label="Header Color" value={this.dealerSettingValue('color_header')} />
							</div>
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'colorpicker'} name="color_header_text" label="Header Text Color" value={this.dealerSettingValue('color_header_text')} />
							</div>
						</div>

						<div className="card-header bg-grey text-capitalize">
							<button className="btn btn-dark form-control">Sidebar Settings</button>
						</div>

						<div className="row">
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'colorpicker'} name="color_sidebar" label="Sidebar Color" value={this.dealerSettingValue('color_sidebar')} />
							</div>
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'colorpicker'} name="color_sidebar_text" label="Sidebar Text Color" value={this.dealerSettingValue('color_sidebar_text')} />
							</div>
						</div>

						<div className="card-header bg-grey text-capitalize">
							<button className="btn btn-dark form-control">Data Stage Settings</button>
						</div>

						<div className="row">
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'colorpicker'} name="color_default_data" label="Default Data Queue Color" value={this.dealerSettingValue('color_default_data')} />
							</div>
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'colorpicker'} name="color_default_data_text" label="Default Data Text Color" value={this.dealerSettingValue('color_default_data_text')} />
							</div>
						</div>

					</div>

					<h3 className="text-center pad-top-1">Vehicle Tab Settings</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="row">
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'colorpicker'} name="color_vehicle_tab" label="Default Vehicle Tab Color" value={this.dealerSettingValue('color_vehicle_tab')} />
							</div>
							<div className="col-md-6">
								<EditDealershipSettings {...this.props} type={'colorpicker'} name="color_vehicle_tab_text" label="Default Vehicle Tab Text Color" value={this.dealerSettingValue('color_vehicle_tab_text')} />
							</div>
						</div>

						<div className="row">
							<h3>Override Vehicle Tab Color</h3>
							<EditDealershipSettings {...this.props} type={'switch'} name="vehicle_tab_override" label="Override Vehicle Tab Color" value={this.dealerSettingValue('vehicle_tab_override')} />
						</div>

						<div className="row">
							<h3>Override Vehicle Tab Color Data</h3>
							<EditDealershipSettings {...this.props} type={'text'} name="vehicle_tab_override_meta" label="Override Vehicle Tab Color Data" value={this.dealerSettingValue('vehicle_tab_override_meta')} />
						</div>

						{html}

					</div>

				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps)(ColorSettings);
